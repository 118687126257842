import styled from "styled-components";

export const VideoPlayContainer = styled.div`
  display: flex;
  padding: 10px 10px;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    padding: 0px 10px;
    flex-direction: column;
    height: 100%;
  }
`;

export const VideoPlayWrapper = styled.div`
  height: 80%;
  width: 75%;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  @media screen and (max-width: 960px) {
    height: 100%;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  outline: none;
  border-radius: 12px;
  &:focus {
    outline: 1px solid;
  }
`;

export const VideoTextBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VideoHeadingText = styled.span`
  margin-top: 10px;
  font-size: 24px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
`;

export const VideoHeadingSub = styled.span`
  margin-top: 2px;
  font-size: 16px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
  margin-bottom: 20px;
  @media screen and (max-width: 960px) {
    margin-bottom: 0px;
  }
`;

export const VideoList = styled.div`
  height: 100%;
  width: 24%;
  overflow-y: auto;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const VideoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
`;

export const VideoBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
`;

export const ListVideo = styled.video`
  width: 180px;
  height: 110px;
  border-radius: 8px;
  object-fit: cover;
  outline: none;
  &:focus {
    outline: 1px solid white;
  }
  @media screen and (max-width: 960px) {
    width: 210px;
    height: 150px;
  }
`;

export const VideoHeadingList = styled.span`
  margin-top: 10px;
  font-size: 14px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
   @media screen and (max-width: 960px) {
     font-size: 16px;
  }
`;

export const VideoHeadingListSub = styled.span`
  margin-top: 2px;
  font-size: 12px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
  @media screen and (max-width: 960px) {
     font-size: 14px;
  }
`;

export const ListHeadingText = styled.span`
  font-size: 28px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
`;
