import styled from "styled-components";

export const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 0 60px;
  @media screen and (max-width: 960px) {
    padding: 0 10px;
  }
`;

export const HeaderText = styled.span`
  color: #001524;
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const SubText = styled.span`
  color: #022c43;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 18px;
  opacity: 0.6;
  @media screen and (max-width: 960px) {
    font-size: 20px;
    font-weight: 900;
    line-height: 36px;
    margin-bottom: 40px;
    text-align: start;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #0a0a3f;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #8f7b45;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 50px;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;

export const ImgContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    padding: 0 5px;
  }
`;
export const Img = styled.img`
  width: 88%;
  height: 95%;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;
