import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>Ernährungsberatung mit Joinpuya</HeaderText>
        <SubText>
          Warum solltest du dich für meine Ernährungspläne entscheiden? Ganz
          einfach: Meine Angebote basieren auf aktuellen wissenschaftlichen
          Erkenntnissen der Ernährungswissenschaft und Stoffwechselphysiologie.
          Jeder Plan ist individuell auf deine spezifischen Bedürfnisse und
          Ziele abgestimmt, sei es Gewichtsabnahme, Muskelaufbau oder gesunde
          Gewichtszunahme.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
