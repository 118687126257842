import React from "react";
import {
  ContainerBox,
  CenterText,
  TextDicriptionContainer,
  DiscriptionNum,
  ContentWrapper,
  TitleText,
  DicriptionTextWrapper,
  DiscriptionText
} from "./styles";

const Details = () => {
  return (
    <ContainerBox>
      <CenterText>Was bietet das 90-Tage-Programm?</CenterText>
      <TextDicriptionContainer>
        <DiscriptionNum>01</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Personaltrainings</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Jeder Körper ist einzigartig, und so sollte auch dein Training
              sein. Ob dein Ziel Fettabbau oder Muskelzuwachs ist – ich erstelle
              für dich einen maßgeschneiderten Trainingsplan:
            </DiscriptionText>
            <DiscriptionText>
              Für Fettabbau: Eine Kombination aus hochintensivem
              Intervalltraining (HIIT) und gezieltem Krafttraining, das deinen
              Stoffwechsel ankurbelt und die Fettverbrennung auf Hochtouren
              bringt, während gleichzeitig deine Muskelmasse erhalten bleibt.
            </DiscriptionText>
            <DiscriptionText>
              Für Muskelaufbau: Progressives Krafttraining, das auf Hypertrophie
              abzielt, damit du kontinuierlich Muskeln aufbaust. Wir arbeiten
              mit strategisch steigender Belastung, um das Muskelwachstum
              optimal zu stimulieren.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>02</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Individueller Ernährungsplan</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Ernährung ist der Schlüssel zum Erfolg – egal, ob du Fett abbauen
              oder Muskeln aufbauen willst. Dein personalisierter Ernährungsplan
              basiert auf deinen individuellen Kalorien- und
              Nährstoffanforderungen:
            </DiscriptionText>
            <DiscriptionText>
              Für Fettabbau: basierend auf Kaloriendefizit, das sicherstellt,
              dass du Fett verlierst, ohne deine Leistungsfähigkeit und
              Muskelmasse zu beeinträchtigen. Du erhältst dabei eine ausgewogene
              Makronährstoffverteilung, die deinen Stoffwechsel unterstützt und
              Heißhungerattacken verhindert.
            </DiscriptionText>
            <DiscriptionText>
              Für Muskelaufbau: basierend auf Kalorienüberschuss mit hohem
              Proteinanteil, der die Muskelproteinsynthese maximiert und
              sicherstellt, dass dein Körper alle Bausteine hat, die er für
              maximales Muskelwachstum braucht.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>03</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Detaillierter, individueller Fitnessplan</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Für Fettabbau: Ein ausgewogener Mix aus Ausdauertraining und
              Kraftübungen, der Fett verbrennt und gleichzeitig deine Muskulatur
              strafft. Durch regelmäßige Anpassungen des Plans bleibt dein
              Körper im „Fat-Burning-Modus“ und verhindert Plateaus.
            </DiscriptionText>
            <DiscriptionText>
              Für Muskelaufbau: Ein periodisierter Trainingsplan, der sich auf
              progressive Überlastung und Regeneration konzentriert, um
              sicherzustellen, dass du kontinuierlich an Kraft und Masse
              gewinnst, ohne Übertraining oder Verletzungen zu riskieren.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>04</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Kostenlose Beratung</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Ich stehe dir während der gesamten 90 Tage zur Seite. Mit
              regelmäßigen Check-ins analysieren wir deine Fortschritte, passen
              den Plan an, wenn nötig, und stellen sicher, dass du auf dem
              richtigen Weg bleibst. Wenn du Fragen zum Training oder der
              Ernährung hast, bin ich jederzeit für dich da!
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>05</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Rund-um-die-Uhr-Support</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Du hast Fragen oder brauchst Motivation? Du kannst jederzeit und
              unkompliziert per E-Mail oder WhatsApp mit mir Kontakt aufnehmen,
              damit du zu jeder Zeit Antworten und Unterstützung bekommst, die
              du brauchst. Egal ob es um technische Fragen, Ernährungstipps oder
              einfach einen Motivationsschub geht – ich bin nur eine Nachricht
              entfernt.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>06</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Unbegrenzte Anrufe</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Deine Transformation beginnt in deinem Kopf. Ich biete dir
              unbegrenzte Anrufe an, um mentale Hürden zu überwinden und
              mögliche Probleme zu lösen. Egal, ob du eine Motivationseinheit
              brauchst, Fragen zum Training hast oder einen mentalen Durchhänger
              erlebst – ich helfe dir, fokussiert und positiv zu bleiben.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>
    </ContainerBox>
  );
};

export default Details;
