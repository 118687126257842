export const trainingVideos = [
  {
    id: 1,
    thumbnailImg:
      "https://builtwithscience.com/wp-content/uploads/2021/01/Big-biceps-thumbnail.jpg",
    heading: "Training for Biceps",
    subHeading: "3x biceps videos",
    video: [
      {
        name: "Hammer Curls",
        subHeading: "3x hammer curves with dummbels",
        video: "/video/hm2.mp4",
      },
      {
        name: "Preacher Curls",
        subHeading: "3x preacher curves with dummbels",
        video: "/video/hm1.mp4",
      },
      {
        name: "Incline Dumbbell Curls",
        subHeading: "3x incline dumbbell curves with dummbels",
        video: "/video/hm.mp4",
      },
      {
        name: "Concentration Curls",
        subHeading: "3x concentration curves with dummbels",
        video: "/video/hm5.mp4",
      },
    ],
  },
  {
    id: 2,
    thumbnailImg:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUYEwHPUWqSe4riEIKtb--qkzd1MIKpMQqKg&s",
    heading: "Training for Biceps",
    subHeading: "3x biceps videos",
    video: [
      {
        name: "Hammer Curls",
        subHeading: "3x hammer curves with dummbels",
        video: "/video/hero.mp4",
      },
      {
        name: "Preacher Curls",
        subHeading: "3x preacher curves with dummbels",
        video: "/video/hero.mp4",
      },
      {
        name: "Incline Dumbbell Curls",
        subHeading: "3x incline dumbbell curves with dummbels",
        video: "/video/hero.mp4",
      },
      {
        name: "Concentration Curls",
        subHeading: "3x concentration curves with dummbels",
        video: "/video/hero.mp4",
      },
    ],
  },
];
