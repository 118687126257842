import React from "react";
// components //
import { Nav } from "../../Compoents/Global";
import { Main } from "../../Compoents/VideoPlay";
// Router //
import { useLocation } from "react-router-dom";

interface Video {
  name: string;
  subHeading: string;
  video: string;
}

interface VideoGroup {
  id: number;
  thumbnailImg: string;
  heading: string;
  subHeading: string;
  video: Video[];
}

interface LocationState {
  state: {
    videoGroup: VideoGroup;
  };
}

const VideoPlay = () => {
  const location = useLocation() as LocationState;
  const videoGroup = location.state?.videoGroup;

  return (
    <>
      <Nav />
      <Main item={videoGroup}/>
    </>
  );
};

export default VideoPlay;
