import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
import { Sub, Offers, VideoHero, Details } from "../../Compoents/PersonalTrainner";

const PersonalTrainer = () => {
  return (
    <>
      <Nav />
      <VideoHero />
      <Sub />
      <Offers />
      <Details />
      <Footer />
    </>
  );
};

export default PersonalTrainer;
