import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// Components //
import { Hero, Sub, Offers } from "../../Compoents/SBeginner";

const SBeginner = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Offers />
      <Footer />
    </>
  );
};

export default SBeginner;
