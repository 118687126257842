import React from "react";
// Global components //
import { Footer } from "../../Compoents/Global";
import {
  Nav,
  Hero,
  Sub,
  VideoFeatures,
  Scroll,
  Features,
} from "../../Compoents";

const Home = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Scroll />
      <VideoFeatures />
      <Features />
      <Footer />
    </>
  );
};

export default Home;
