import React from "react";
import {
  MainContainer,
  HeaderText,
  HeaderBoxWrapper,
  BoxListWrapper,
  SubText,
} from "./styles";

const Details = () => {
  return (
    <MainContainer>
      <HeaderBoxWrapper>
        <HeaderText>Basic – 1 Stunde Personal Training</HeaderText>
        <BoxListWrapper>
          <SubText>
            ◉ Ideal für Einsteiger oder alle, die eine individuelle Beratung und
            erste Impulse für ihr Training suchen. In dieser intensiven Stunde
            gehe ich auf deine speziellen Bedürfnisse ein und zeige dir gezielte
            Übungen, um dich auf den richtigen Weg zu bringen. Perfekt, um
            deinen Körper in Bewegung zu setzen und erste Fortschritte zu
            spüren.
          </SubText>
        </BoxListWrapper>
        <HeaderText>Standard – 10 Sessions</HeaderText>
        <BoxListWrapper>
          <SubText>
            ◉ Für alle, die mehr wollen! Mit 10 Personaltrainings hast du die
            Möglichkeit, konsequent an deinen Zielen zu arbeiten. Wir entwickeln
            einen strukturierten Trainingsplan, der dich Schritt für Schritt
            fitter und stärker macht. Durch regelmäßige Sessions bauen wir
            gezielt auf deinen Fortschritten auf und passen das Programm
            individuell an.
          </SubText>
        </BoxListWrapper>
        <HeaderText>Premium – 20 Sessions</HeaderText>
        <BoxListWrapper>
          <SubText>
            ◉ Das Rundum-sorglos-Paket für ambitionierte Fitnessziele! Mit 20
            Personaltrainings erreichst du nicht nur schnelle Erfolge, sondern
            langfristige Veränderung. Dieses Paket bietet dir die Möglichkeit,
            dich intensiv mit deinem Körper auseinanderzusetzen und konstant an
            Kraft, Ausdauer und Flexibilität zu arbeiten. Gemeinsam maximieren
            wir dein Potenzial und sichern nachhaltige Erfolge
          </SubText>
        </BoxListWrapper>
        <HeaderText>
          Starte jetzt deine Fitnessreise – ich unterstütze dich bei jedem
          Schritt!
        </HeaderText>
      </HeaderBoxWrapper>
    </MainContainer>
  );
};

export default Details;
