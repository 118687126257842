import React from "react";
import {
  MainContainer,
  HeaderText,
  HeaderBoxWrapper,
  BoxListWrapper,
  SubText,
} from "./styles";
// Component //
import Feat from "../Feat";

const Details = () => {
  return (
    <MainContainer>
      <Feat />
      <HeaderBoxWrapper>
        <HeaderText>
          In meinem Angebot findest du ebenfalls Schwimmtrainings für
          Triatleth*innen und für Menschen mit Angst vor Wasser
        </HeaderText>
        <HeaderText>Meine Leistungen:</HeaderText>
        <BoxListWrapper>
          <SubText>
            ◉ Professionelle Betreuung durch meine jahrelange Erfahrung{" "}
          </SubText>
          <SubText>
            ◉ Individuelle Anpassung des Trainings an dein Lerntempo{" "}
          </SubText>
          <SubText>
            ◉ Vermittlung grundlegender Schwimmtechniken und Sicherheitsregeln{" "}
          </SubText>
          <SubText>◉ 1:1 Betreuung für optimale Lernerfolge </SubText>
          <SubText>
            ◉ Spielerisches Erlernen grundlegender Schwimmtechniken für Kinder
          </SubText>
          <SubText>◉ Sicheres und motivierendes Umfeld für das Kind</SubText>
        </BoxListWrapper>
        <HeaderText>Details zum Unterricht:</HeaderText>
        <BoxListWrapper>
          <SubText>
            Der Unterricht findet im Kombibad Seestraße oder im Oderberger Hotel
            in Berlin statt. Der Unterricht in anderen Schwimmhallen ist nach
            vorheriger Rücksprache ggf. möglich. Die Kursdauer beträgt 60
            Minuten.
          </SubText>
        </BoxListWrapper>
      </HeaderBoxWrapper>
    </MainContainer>
  );
};

export default Details;
