import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 0 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 25px 5px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 35%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
`;

export const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  flex-direction: column;
  color: #022c43;
  @media screen and (max-width: 960px) {
    font-size: 26px;
  }
`;

export const SubText = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  align-self: center;
  color: #022c43;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

export const BtnHeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    width: 40%;
  }
`;

export const BtnContainer = styled.a`
  display: flex;
  height: 60px;
  width: 60px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  &:hover {
    background-color: #c2ffc7;
  }
  @media screen and (max-width: 960px) {
    margin-top: 15px;
  }
`;
