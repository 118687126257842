/* eslint-disable react/jsx-key */
"use client";
import React from "react";
import {
  ListContainer,
  List,
  ListBox,
  VideoContainer,
  VideoWrapper,
  Video,
  VideoTextContainer,
  VideoText,
  SubBtnContainer,
  BtnContainerDownload,
  BtnTextDownload,
} from "../styles";
// Router //
import { Link } from "react-router-dom";

interface MuscleData {
  data: [
    {
      name: string;
      sets: number;
      reps: number;
      video: string;
    }
  ];
}

const BoxLayout: React.FC<MuscleData> = ({ data }) => {
  return (
    <ListContainer>
      <List>
        {data.map((item) => (
          <ListBox key={item.name}>
            <VideoContainer>
              <VideoWrapper>
                <Video controls={false} playsInline loop autoPlay muted>
                  <source src={item.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </Video>
              </VideoWrapper>
              <VideoTextContainer>
                <VideoText>{item.name}</VideoText>
              </VideoTextContainer>
            </VideoContainer>
          </ListBox>
        ))}
        {/* <SubBtnContainer>
          <BtnContainerDownload>
            <Link to="/videos-home">
              <BtnTextDownload>More videos</BtnTextDownload>
            </Link>
          </BtnContainerDownload>
        </SubBtnContainer> */}
      </List>
    </ListContainer>
  );
};

export default BoxLayout;
