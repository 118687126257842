import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubTextContainer,
  SubText,
  SubTextTwo,
  MainText,
  LinkText,
} from "./styles";

const Main = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>Datenschutz</HeaderText>
      </HeaderContainer>
      <SubTextContainer>
        <SubText>Einleitung und allgemeine Informationen:</SubText>
        <MainText>
          Der Schutz Ihrer Daten ist uns wichtig. Mit der folgenden
          Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten
          Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“
          bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen
          unserer Dienstleistungen und Angebote verarbeiten. Eine Nutzung der
          Internetseite www.joinpuya.de ist grundsätzlich ohne jede Angabe
          personenbezogener Daten möglich. Sofern eine betroffene Person
          besondere Services unseres Unternehmens über unsere Internetseite in
          Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
          personenbezogener Daten erforderlich werden. Die Datenschutzerklärung
          gilt für alle von uns durchgeführten Verarbeitungen personenbezogener
          Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch
          insbesondere auf der Webseite, in mobilen Applikationen sowie
          innerhalb externer Onlinepräsenzen, wie z. B. der Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“) von
          Joinpuya.
        </MainText>
        <SubTextTwo>Verantwortlicher:</SubTextTwo>
        <MainText>Pouya Pourebrahim </MainText>
        <MainText>Teichstr. 45 | 13407 Berlin </MainText>
        <MainText>E-Mail: fitness@joinpuya.de</MainText>
        <MainText>Telefonnummer: +49 157 58 07 43 29</MainText>
        <MainText>
          Impressum: <LinkText>https://www.joinpuya.de/#/impressum</LinkText>
        </MainText>

        <SubTextTwo>
          Erhebung und Verarbeitung personenbezogener Daten:
        </SubTextTwo>
        <MainText>
          Wir verarbeiten personenbezogene Daten nur, wenn dies für die
          Erbringung unserer Dienstleistungen erforderlich ist oder Sie uns
          freiwillig Daten zur Verfügung stellen. Dies kann folgende Daten
          umfassen: · Kontaktinformationen: Name, Anschrift, E-Mail-Adresse,
          Telefonnummer · Trainingsdaten: Gesundheitsdaten (wie z.B. ärztliche
          Atteste), Fitnesslevel, Trainingshistorie und -ziele · Vertragsdaten:
          Zahlungsinformationen und vertragliche Vereinbarungen Die
          Bereitstellung dieser Daten ist notwendig, um ein individuelles
          Trainingsprogramm zu erstellen, Sie bei gesundheitlichen Fragen
          angemessen zu beraten und die Vertragsbeziehung durchzuführen.
        </MainText>

        <SubTextTwo>Cookies</SubTextTwo>
        <MainText>
          (Die Internetseite www.joinpuya.de verwendet Cookies. Cookies sind
          Textdateien, welche über einen Internetbrowser auf einem
          Computersystem abgelegt und gespeichert werden. Zahlreiche
          Internetseiten und Server verwenden Cookies. Viele Cookies enthalten
          eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung
          des Cookies. Sie besteht aus einer Zeichenfolge, durch welche
          Internetseiten und Server dem konkreten Internetbrowser zugeordnet
          werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es
          den besuchten Internetseiten und Servern, den individuellen Browser
          der betroffenen Person von anderen Internetbrowsern, die andere
          Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser
          kann über die eindeutige Cookie-ID wiedererkannt und identifiziert
          werden. Durch den Einsatz von Cookies kann Joinpuya den Nutzer*innen
          dieser Internetseite nutzerfreundlichere Services bereitstellen, die
          ohne die Cookie-Setzung nicht möglich wären. Mittels eines Cookies
          können die Informationen und Angebote auf unserer Internetseite im
          Sinne des/der Nutzer*in optimiert werden. Cookies ermöglichen uns, wie
          bereits erwähnt, die Nutzer*innen unserer Internetseite
          wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den
          Nutzer*innen die Verwendung unserer Internetseite zu erleichtern. Der
          Nutzer*in einer Internetseite, die Cookies verwendet, muss
          beispielsweise nicht bei jedem Besuch der Internetseite erneut
          seine/ihre Zugangsdaten eingeben, weil dies von der Internetseite und
          dem auf dem Computersystem des/der Nutzer*in abgelegten Cookie
          übernommen wird. Ein weiteres Beispiel ist das Cookie eines
          Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel,
          die ein/eine Kund*in in den virtuellen Warenkorb gelegt hat, über ein
          Cookie. Die betroffene Person kann die Setzung von Cookies durch
          unsere Internetseite jederzeit mittels einer entsprechenden
          Einstellung des genutzten Internetbrowsers verhindern und damit der
          Setzung von Cookies dauerhaft widersprechen. Ferner können bereits
          gesetzte Cookies jederzeit über einen Internetbrowser oder andere
          Softwareprogramme gelöscht werden. Dies ist in allen gängigen
          Internetbrowsern möglich. Deaktiviert die betroffene Person die
          Setzung von Cookies in dem genutzten Internetbrowser, sind unter
          Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
          nutzbar.
        </MainText>

        <SubTextTwo>
          Erfassung von allgemeinen Daten und Informationen
        </SubTextTwo>
        <MainText>
          Die Internetseite www.joinpuya.de erfasst mit jedem Aufruf der
          Internetseite durch eine betroffene Person oder ein automatisiertes
          System eine Reihe von allgemeinen Daten und Informationen. Diese
          allgemeinen Daten und Informationen werden in den Logfiles des Servers
          gespeichert. Erfasst werden können: · die verwendeten Browsertypen und
          Versionen, · das vom zugreifenden System verwendete Betriebssystem, ·
          die Internetseite, von welcher ein zugreifendes System auf unsere
          Internetseite gelangt (sogenannte Referrer), · die Unterwebseiten,
          welche über ein zugreifendes System auf unserer Internetseite
          angesteuert werden, · das Datum und die Uhrzeit eines Zugriffs auf die
          Internetseite, · eine Internet-Protokoll-Adresse (IP-Adresse), · der
          Internet-Service-Provider des zugreifenden Systems und · sonstige
          ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
          Angriffen auf unsere informationstechnologischen Systeme dienen. Bei
          der Nutzung dieser allgemeinen Daten und Informationen zieht Joinpuya
          keine Rückschlüsse auf die betroffene Person. Diese Informationen
          werden vielmehr benötigt, um: · die Inhalte unserer Internetseite
          korrekt auszuliefern, · die Inhalte unserer Internetseite sowie die
          Werbung für diese zu optimieren, · die dauerhafte Funktionsfähigkeit
          unserer informationstechnologischen Systeme und der Technik unserer
          Internetseite zu gewährleisten sowie · um Strafverfolgungsbehörden im
          Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
          Informationen bereitzustellen. Diese anonym erhobenen Daten und
          Informationen werden durch Joinpuya daher einerseits statistisch und
          ferner mit dem Ziel ausgewertet, den Datenschutz und die
          Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein
          optimales Schutzniveau für die von uns verarbeiteten personenbezogenen
          Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
          getrennt von allen durch eine betroffene Person angegebenen
          personenbezogenen Daten gespeichert.
        </MainText>

        <SubTextTwo>Kontaktmöglichkeit über die Internetseite</SubTextTwo>
        <MainText>
          Die Internetseite www.joinpuya.de enthält aufgrund von gesetzlichen
          Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme
          zu unserem Unternehmen sowie eine unmittelbare Kommunikation mit uns
          ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten
          elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene
          Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem
          für die Verarbeitung Verantwortlichen aufnimmt, werden die von der
          betroffenen Person übermittelten personenbezogenen Daten automatisch
          gespeichert. Solche auf freiwilliger Basis von einer betroffenen
          Person an den für die Verarbeitung Verantwortlichen übermittelten
          personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
          Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine
          Weitergabe dieser personenbezogenen Daten an Dritte.
        </MainText>

        <SubTextTwo>
          Datenschutzbestimmungen zum Einsatz und zur Verwendung von Social
          Media
        </SubTextTwo>
        <MainText>
          Unsere Website verwendet Social-Media-Plugins von Facebook, Instagram,
          TikTok und YouTube, um Ihnen eine interaktive Nutzer*innenerfahrung zu
          bieten und unsere Inhalte auf diesen Plattformen zu teilen. Im
          Folgenden informieren wir Sie darüber, welche Daten bei der Nutzung
          dieser Plugins verarbeitet werden und wie wir mit diesen Daten
          umgehen. (1) Zweck der Einbindung von Social-Media-Plugins Die
          Einbindung von Facebook, Instagram, TikTok und YouTube erfolgt, um
          unsere Inhalte über soziale Netzwerke zu verbreiten, unsere Website
          nutzer*innenfreundlicher zu gestalten und den Dialog mit unseren
          Nutzer*innen zu fördern. Social-Media-Plugins sind kleine Programme,
          die es Ihnen ermöglichen, direkt von unserer Website auf Inhalte
          dieser Netzwerke zuzugreifen und diese zu teilen. (2) Art und Umfang
          der Datenerhebung durch Social-Media-Plugins Wenn Sie eine Seite
          aufrufen, die ein Social-Media-Plugin enthält, baut Ihr Browser
          möglicherweise eine direkte Verbindung mit den Servern des jeweiligen
          sozialen Netzwerks auf. Dabei können personenbezogene Daten wie Ihre
          IP-Adresse, Ihr Standort und technische Details Ihres Geräts
          übermittelt werden. In der Regel speichern die Anbieter*innen auch,
          dass Sie unsere Seite besucht haben und gegebenenfalls, dass Sie
          währenddessen in Ihrem Social-Media-Konto eingeloggt waren. (3)
          Verantwortlicher für die Datenverarbeitung und Datenschutzrichtlinien
          der Anbieter Die Datenverarbeitung durch die Anbieter*innen erfolgt
          auf Grundlage der jeweiligen Datenschutzrichtlinien: · Facebook und
          Instagram (Meta Platforms Inc.) Anbieter: Meta Platforms Inc., 1601
          Willow Road, Menlo Park, CA 94025, USA Datenschutzerklärung:
          https://www.facebook.com/privacy/policy/ · TikTok Anbieter: TikTok
          Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Irland
          Datenschutzerklärung: https://www.tiktok.com/legal/privacy-policy ·
          YouTube (Google LLC) Anbieter: Google LLC, 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA Datenschutzerklärung:
          https://policies.google.com/privacy Wir haben keinen Einfluss auf die
          Art und den Umfang der Datenverarbeitung durch diese Anbieter*innen.
          Weitere Informationen entnehmen Sie bitte den Datenschutzbestimmungen
          des/der jeweiligen Anbieter*in. (4) Rechtsgrundlage für die
          Datenverarbeitung Die Rechtsgrundlage für die Verarbeitung
          personenbezogener Daten im Zusammenhang mit Social-Media-Plugins ist
          Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO, sofern Sie diese
          durch die Nutzung des Plugins oder durch eine Einwilligungsabfrage auf
          unserer Website erteilt haben. (5) Speicherung und Übermittlung
          personenbezogener Daten Daten, die im Rahmen der Nutzung der
          Social-Media-Plugins erhoben werden, können in die USA oder andere
          Drittstaaten übermittelt werden. Bitte beachten Sie, dass diese Länder
          ggf. nicht das gleiche Datenschutzniveau wie die EU bieten. Die
          Social-Media-Anbieter sind jedoch durch Standardvertragsklauseln oder
          andere geeignete Garantien verpflichtet, ein angemessenes
          Datenschutzniveau sicherzustellen. (6) Widerspruch und Widerruf Ihrer
          Einwilligung Sie haben das Recht, Ihre Einwilligung zur Verarbeitung
          Ihrer Daten jederzeit zu widerrufen. Um eine Übermittlung an die
          Social-Media-Anbieter zu verhindern, loggen Sie sich vor dem Besuch
          unserer Seite aus Ihrem Social-Media-Konto aus oder deaktivieren Sie
          die Speicherung von Cookies in Ihrem Browser. Weitere Einstellungen
          und Widerspruchsmöglichkeiten zur Nutzung Ihrer Daten finden Sie in
          den Datenschutzeinstellungen des jeweiligen Anbieters. (7) Hinweise zu
          Cookies und Tracking Die Social-Media-Plugins setzen häufig Cookies,
          um das Nutzerverhalten nachzuvollziehen und die Funktionalität zu
          verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät
          gespeichert werden. In unseren Cookie-Einstellungen haben Sie die
          Möglichkeit, die Nutzung von Social-Media-Cookies zu aktivieren oder
          zu deaktivieren. Weitere Informationen zur Nutzung von Cookies finden
          Sie in unserer separaten Cookie-Richtlinie.
        </MainText>

        <SubTextTwo>
          Datenschutzbestimmungen zum Einsatz und zur Verwendung von
          Google-Analytics
        </SubTextTwo>
        <MainText>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
          die Komponente Google Analytics (mit Anonymisierungsfunktion)
          integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse
          ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten
          von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter
          anderem Daten darüber, von welcher Internetseite eine betroffene
          Person auf eine Internetseite gekommen ist (sogenannte Referrer), auf
          welche Unterseiten der Internetseite zugegriffen oder wie oft und für
          welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse
          wird überwiegend zur Optimierung einer Internetseite und zur
          Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
          Betreibergesellschaft der Google-Analytics-Komponente ist die Google
          Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5,
          Ireland. Der für die Verarbeitung Verantwortliche verwendet für die
          Web-Analyse über Google Analytics den Zusatz “_gat._anonymizeIp”.
          Mittels dieses Zusatzes wird die IP-Adresse des Internetanschlusses
          der betroffenen Person von Google gekürzt und anonymisiert, wenn der
          Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der
          Europäischen Union oder aus einem anderen Vertragsstaat des Abkommens
          über den Europäischen Wirtschaftsraum erfolgt. Der Zweck der
          Google-Analytics-Komponente ist die Analyse der Besucherströme auf
          unserer Internetseite. Google nutzt die gewonnenen Daten und
          Informationen unter anderem dazu, die Nutzung unserer Internetseite
          auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf
          unseren Internetseiten aufzeigen, zusammenzustellen, und um weitere
          mit der Nutzung unserer Internetseite in Verbindung stehende
          Dienstleistungen zu erbringen. Google Analytics setzt ein Cookie auf
          dem informationstechnologischen System der betroffenen Person. Was
          Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies
          wird Google eine Analyse der Benutzung unserer Internetseite
          ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser
          Internetseite, die durch den für die Verarbeitung Verantwortlichen
          betrieben wird und auf welcher eine Google-Analytics-Komponente
          integriert wurde, wird der Internetbrowser auf dem
          informationstechnologischen System der betroffenen Person automatisch
          durch die jeweilige Google-Analytics-Komponente veranlasst, Daten zum
          Zwecke der Online-Analyse an Google zu übermitteln. Im Rahmen dieses
          technischen Verfahrens erhält Google Kenntnis über personenbezogene
          Daten, wie der IP-Adresse der betroffenen Person, die Google unter
          anderem dazu dienen, die Herkunft der Besucher und Klicks
          nachzuvollziehen und in der Folge Provisionsabrechnungen zu
          ermöglichen. Mittels des Cookies werden personenbezogene
          Informationen, beispielsweise die Zugriffszeit, der Ort, von welchem
          ein Zugriff ausging und die Häufigkeit der Besuche unserer
          Internetseite durch die betroffene Person, gespeichert. Bei jedem
          Besuch unserer Internetseiten werden diese personenbezogenen Daten,
          einschließlich der IP-Adresse des von der betroffenen Person genutzten
          Internetanschlusses, an Google in den Vereinigten Staaten von Amerika
          übertragen. Diese personenbezogenen Daten werden durch Google in den
          Vereinigten Staaten von Amerika gespeichert. Google gibt diese über
          das technische Verfahren erhobenen personenbezogenen Daten unter
          Umständen an Dritte weiter. Die betroffene Person kann die Setzung von
          Cookies durch unsere Internetseite, wie oben bereits dargestellt,
          jederzeit mittels einer entsprechenden Einstellung des genutzten
          Internetbrowsers verhindern und damit der Setzung von Cookies
          dauerhaft widersprechen. Eine solche Einstellung des genutzten
          Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
          informationstechnologischen System der betroffenen Person setzt. Zudem
          kann ein von Google Analytics bereits gesetzter Cookie jederzeit über
          den Internetbrowser oder andere Softwareprogramme gelöscht werden.
          Ferner besteht für die betroffene Person die Möglichkeit, einer
          Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
          dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser
          Daten durch Google zu widersprechen und eine solche zu verhindern.
          Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
          https://tools.google.com/dlpage/gaoptout herunterladen und
          installieren. Dieses Browser-Add-On teilt Google Analytics über
          JavaScript mit, dass keine Daten und Informationen zu den Besuchen von
          Internetseiten an Google Analytics übermittelt werden dürfen. Die
          Installation des Browser-Add-Ons wird von Google als Widerspruch
          gewertet. Wird das informationstechnologische System der betroffenen
          Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
          installiert, muss durch die betroffene Person eine erneute
          Installation des Browser-Add-Ons erfolgen, um Google Analytics zu
          deaktivieren. Sofern das Browser-Add-On durch die betroffene Person
          oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist,
          deinstalliert oder deaktiviert wird, besteht die Möglichkeit der
          Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons.
          Weitere Informationen und die geltenden Datenschutzbestimmungen von
          Google können unter https://www.google.de/intl/de/policies/privacy/
          und unter http://www.google.com/analytics/terms/de.html abgerufen
          werden. Google Analytics wird unter diesem Link
          https://www.google.com/intl/de_de/analytics/ genauer erläutert.
        </MainText>

        <SubTextTwo>Zwecke der Datenverarbeitung</SubTextTwo>
        <MainText>
          Ihre Daten werden für folgende Zwecke erhoben und verarbeitet: ·
          Erstellung und Anpassung von Trainings-, Schwimm- und Ernährungsplänen
          · Verwaltung der Trainer-Kunden-Beziehung · Kommunikation bezüglich
          Terminvereinbarungen und organisatorischer Informationen ·
          Zahlungsabwicklung · Anpassung des Trainingsprogramms basierend auf
          dem individuellen Fitnesszustand und Gesundheitsinformationen
        </MainText>

        <SubTextTwo>Maßgebliche Rechtsgrundlagen</SubTextTwo>
        <MainText>
          Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
          DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
          nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit. Die Rechtsgrundlage für die Datenverarbeitung richtet sich nach
          Art. 6 Abs. 1 DSGVO: · Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
          - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
          sie betreffenden personenbezogenen Daten (z.B. Gesundheitsdaten) für
          einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben. ·
          Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
          lit. b) DSGVO) - Die Verarbeitung ist für die Erfüllung eines
          Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur
          Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage
          der betroffenen Person erfolgen. · Rechtliche Verpflichtung (Art. 6
          Abs. 1, S.1 lit. c) DSGVO) - die Verarbeitung ist zur Erfüllung einer
          rechtlichen Verpflichtung (z.B. Aufbewahrungspflichten) erforderlich,
          der der Verantwortliche unterliegt. · Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO) - Die Verarbeitung ist zur Wahrung der
          berechtigten Interessen des Verantwortlichen oder eines Dritten
          erforderlich, sofern nicht die Interessen oder Grundrechte und
          Grundfreiheiten der betroffenen Person, die den Schutz
          personenbezogener Daten erfordern, überwiegen.
        </MainText>

        <SubTextTwo>Nationale Datenschutzregelungen in Deutschland:</SubTextTwo>
        <MainText>
          Zusätzlich zu den Datenschutzregelungen der
          Datenschutz-Grundverordnung gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
          Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Ferner können
          Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
          gelangen. Weitergabe von Daten an Dritte Wir geben Ihre Daten nur dann
          an Dritte weiter, wenn dies zur Vertragserfüllung erforderlich ist, z.
          B. an Dienstleister für die Zahlungsabwicklung, oder wenn Sie
          ausdrücklich eingewilligt haben. Eine Weitergabe an Dritte ohne Ihre
          ausdrückliche Einwilligung erfolgt nicht, es sei denn, wir sind
          gesetzlich dazu verpflichtet. Dauer der Speicherung Personenbezogene
          Daten werden nur so lange gespeichert, wie dies zur Erfüllung des
          jeweiligen Zwecks notwendig ist oder eine gesetzliche
          Aufbewahrungspflicht besteht. Nach Erfüllung des Zwecks bzw. Ablauf
          der gesetzlichen Fristen werden die Daten gelöscht. Rechte der
          betroffenen Personen Sie haben das Recht: · Auskunft über Ihre
          verarbeiteten Daten zu erhalten (Art. 15 DSGVO); · Berichtigung
          unrichtiger Daten zu verlangen (Art. 16 DSGVO); · Löschung Ihrer Daten
          zu verlangen (Art. 17 DSGVO), sofern keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen; · Einschränkung der
          Verarbeitung zu verlangen (Art. 18 DSGVO); · der Verarbeitung Ihrer
          Daten zu widersprechen (Art. 21 DSGVO); · Ihre Einwilligung jederzeit
          zu widerrufen (Art. 7 Abs. 3 DSGVO), sofern die Verarbeitung auf Ihrer
          Einwilligung beruht; · sich bei der Aufsichtsbehörde zu beschweren,
          wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen die
          DSGVO verstößt.
        </MainText>

        <SubTextTwo>Datensicherheit</SubTextTwo>
        <MainText>
          Wir setzen technische und organisatorische Maßnahmen ein, um Ihre
          Daten vor unberechtigtem Zugriff, Verlust und Missbrauch zu schützen.
          Diese Sicherheitsmaßnahmen werden regelmäßig überprüft und dem Stand
          der Technik angepasst. Änderung der Datenschutzbestimmungen Wir
          behalten uns vor, diese Datenschutzbestimmungen anzupassen, um sie an
          rechtliche Änderungen oder Änderungen der Dienstleistung anzupassen.
          Die jeweils aktuelle Version ist auf Anfrage erhältlich oder wird auf
          unserer Webseite veröffentlicht. Kontakt Für Fragen zur Verarbeitung
          Ihrer personenbezogenen Daten oder zur Geltendmachung Ihrer Rechte
          wenden Sie sich bitte an die oben genannten Kontaktdaten.
        </MainText>
      </SubTextContainer>
    </HeroContainer>
  );
};

export default Main;
