import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import Food from "../../../assets/imgs/aboutImgOne.png";

const Hero = () => {

  return (
    <HeroContainer>
      <ImgContainer>
        <Img src={Food} />
      </ImgContainer>
      <HeaderContainer>
        <HeaderText>Eine kurze Geschichte über mich</HeaderText>
        <SubText>
          Ich bin Pouya und lebe in Berlin. Ich mache nun seit 28
          Jahren leidenschaftlich gern Sport und habe in den letzten 20 Jahren
          eine Karriere als Profischwimmer gemacht. Ich habe Sportwissenschaften
          studiert und diverse Zertifikate in diesem Bereich erhalten. In den
          letzten 5 Jahren begann ich eine intensive Fitness-Reise.
        </SubText>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Hero;
