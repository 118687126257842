import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubTextContainer,
  SubText,
  SubTextTwo,
  MainText,
} from "./styles";

const Main = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>Allgemeine Geschäftsbedingungen (AGB)</HeaderText>
      </HeaderContainer>
      <SubTextContainer>
        <SubText>Geltungsbereich:</SubText>
        <MainText>
          Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Verträge,
          die zwischen dem Schwimm- und Fitnesstrainer Pouya Pourebrahim
          (Joinpuya) (im Folgenden "Trainer") und dem/der Kund*in (im Folgenden
          "Kund*in“) geschlossen werden. Abweichende Bedingungen des/der Kund*in
          werden nicht anerkannt, es sei denn, der Trainer stimmt ihrer Geltung
          ausdrücklich schriftlich zu.
        </MainText>

        <SubTextTwo>Vertragsgegenstand:</SubTextTwo>
        <MainText>
          Der Trainer bietet individuelle Schwimm- und Fitnesstrainings an.
          Diese sind in ihrer aktuellen Form auf der Website www.joinpuya.de zu
          finden. Der genaue Umfang und Inhalt des Trainings wird im Rahmen des
          jeweiligen Vertragsverhältnisses festgelegt. Der Vertrag kommt durch
          die Buchung des Kunden und die Bestätigung des Trainers zustande.
        </MainText>

        <SubTextTwo>Teilnahmevoraussetzungen:</SubTextTwo>
        <MainText>
          Der/Die Kund*in bestätigt mit seiner/ihrer Buchung, dass er/sie
          körperlich in der Lage ist, an den Trainingsstunden teilzunehmen. Bei
          bekannten gesundheitlichen Einschränkungen, Erkrankungen oder
          Verletzungen ist der Trainer vor Beginn der Trainingseinheit zu
          informieren. Der/Die Kund*in nimmt auf eigenes Risiko am Training
          teil. Der Trainer haftet nicht für Schäden, die auf nicht mitgeteilte
          gesundheitliche Einschränkungen zurückzuführen sind.
        </MainText>

        <SubTextTwo>Leistungsumfang und -durchführung:</SubTextTwo>
        <MainText>
          Der Trainer verpflichtet sich, die vereinbarten Trainingseinheiten
          sorgfältig und nach bestem Wissen und Gewissen durchzuführen. Der
          Trainer behält sich jedoch vor, Trainingseinheiten zu verschieben oder
          abzusagen, wenn dies aus Gründen wie Krankheit, unvorhersehbaren
          Ereignissen oder höherer Gewalt erforderlich ist. In diesen Fällen
          wird ein Ersatztermin vereinbart.
        </MainText>

        <SubTextTwo>Zahlungsbedingungen:</SubTextTwo>
        <MainText>
          Das Honorar für die Trainingseinheiten wird vor Beginn des Trainings
          vereinbart und ist nach Erbringung der Leistung sofort fällig, sofern
          nichts anderes vereinbart wurde. Die Zahlung erfolgt per Überweisung
          auf das Geschäftskonto von Joinpuya. Bei wiederkehrenden Leistungen
          (z. B. monatliche Abos) erfolgt die Abrechnung im Voraus.
        </MainText>

        <SubTextTwo>Stornierung und Absagen:</SubTextTwo>
        <MainText>
          Der/Die Kund*in kann gebuchte Trainingseinheiten bis zu 24 Stunden vor
          Beginn kostenfrei stornieren. Bei späteren Absagen oder
          Nichterscheinen wird das volle Honorar fällig, es sei denn, der/die
          Kund*in kann einen wichtigen Grund für die Absage nachweisen (z. B.
          Krankheit). Der Trainer ist berechtigt, das Training kurzfristig
          abzusagen. In diesem Fall entfällt die Zahlungspflicht des/der Kund*in
          für die ausgefallene Einheit.
        </MainText>

        <SubTextTwo>Haftung:</SubTextTwo>
        <MainText>
          Der Trainer haftet für Schäden, die vorsätzlich oder grob fahrlässig
          verursacht wurden. Für einfache Fahrlässigkeit haftet der Trainer nur
          bei Verletzung wesentlicher Vertragspflichten und nur im Rahmen des
          vorhersehbaren, vertragstypischen Schadens. Der Trainer haftet nicht
          für die Garderobe oder andere persönliche Gegenstände des Kunden
          während der Trainingseinheiten.
        </MainText>

        <SubTextTwo>Datenschutz:</SubTextTwo>
        <MainText>
          Der Trainer verpflichtet sich, alle persönlichen Daten des/der Kund*in
          vertraulich zu behandeln und ausschließlich zur Vertragsabwicklung und
          zur Durchführung der Trainingseinheiten zu verwenden. Die Daten werden
          nicht an Dritte weitergegeben, es sei denn, dies ist zur
          Vertragserfüllung erforderlich oder gesetzlich vorgeschrieben.
        </MainText>

        <SubTextTwo>Änderungen der AGB:</SubTextTwo>
        <MainText>
          Der Trainer behält sich das Recht vor, diese AGB jederzeit zu ändern.
          Änderungen werden dem/der Kund*in in geeigneter Weise bekannt gegeben.
          Widerspricht der/die Kund*in den Änderungen nicht innerhalb von 14
          Tagen nach Bekanntgabe, gelten die geänderten AGB als akzeptiert.
        </MainText>

        <SubTextTwo>Schlussbestimmungen:</SubTextTwo>
        <MainText>
          Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so
          bleibt die Wirksamkeit der übrigen Bestimmungen unberührt. Anstelle
          der unwirksamen Bestimmung tritt eine Regelung, die der unwirksamen
          Bestimmung wirtschaftlich am nächsten kommt. Es gilt deutsches Recht.
          Gerichtsstand ist der Wohnsitz des Trainers, sofern gesetzlich
          zulässig.
        </MainText>
      </SubTextContainer>
    </HeroContainer>
  );
};

export default Main;
