import React, { useState } from "react";
import {
  Container,
  ContainerWrapper,
  LogoImg,
  DetailsContainer,
  NavItem,
  BtnContainer,
  BtnText,
  DropdownMenu,
  DropdownItem,
  MobileIcon,
  NavItemBookNow,
} from "./styles";
import { Link } from "react-router-dom";
// logo //
import Logo from "../../../assets/imgs/logo.png";

const Nav: React.FC = () => {
  const [fitnessDropdownVisible, setFitnessDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return (
    <Container>
      <ContainerWrapper>
        <Link to="/">
          <LogoImg src={Logo} alt="Logo" />
        </Link>
        <MobileIcon onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
          &#9776;
        </MobileIcon>
        <DetailsContainer mobileMenuVisible={mobileMenuVisible}>
          <Link to="/" onClick={() => setMobileMenuVisible(false)}>
            <NavItem>Startseite</NavItem>
          </Link>
          <Link to="/uber-mich">
            <NavItem>Über mich</NavItem>
          </Link>
          <NavItem
            onMouseEnter={() => setFitnessDropdownVisible(true)}
            onMouseLeave={() => setFitnessDropdownVisible(false)}
            onClick={() => setFitnessDropdownVisible(!fitnessDropdownVisible)}
          >
            Fitness▾
            {fitnessDropdownVisible && (
              <DropdownMenu>
                <Link
                  to="/personal-training"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  <DropdownItem>Personal Training</DropdownItem>
                </Link>
                <Link
                  to="/ernahrungsplan"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  <DropdownItem>Ernährungsplan</DropdownItem>
                </Link>
                <Link
                  to="/training-plan"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  <DropdownItem>Training plan</DropdownItem>
                </Link>
                <Link
                  to="/neunzig-tage-transformation"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  <DropdownItem>90-day transformation</DropdownItem>
                </Link>
              </DropdownMenu>
            )}
          </NavItem>
          <Link
            to="/schwimmen"
            onClick={() => setMobileMenuVisible(false)}
          >
            <NavItem>Schwimmen</NavItem>
          </Link>
          <Link to="/angebote">
            <NavItem onClick={() => setMobileMenuVisible(false)}>
              Angebote
            </NavItem>
          </Link>
          <Link to="/jetzt-buchen">
            <NavItemBookNow onClick={() => setMobileMenuVisible(false)}>
              Jetzt buchen
            </NavItemBookNow>
          </Link>
        </DetailsContainer>
        <BtnContainer>
          <Link to="/jetzt-buchen">
            <BtnText>Jetzt buchen</BtnText>
          </Link>
        </BtnContainer>
      </ContainerWrapper>
    </Container>
  );
};

export default Nav;
