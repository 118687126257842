// CookieConsent.js
import React, { useState } from "react";
// styles //
import {
  Banner,
  BannerContent,
  Text,
  ButtonGroup,
  AcceptButton,
  DeclineButton,
} from "./styles";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true);
  const handleAccept = () => {
    setShowBanner(false);
  };
  const handleDecline = () => {
    setShowBanner(false);
  };
  if (!showBanner) return null;
  return (
    <Banner>
      <BannerContent>
        <Text>
          Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu
          verbessern. Mit "Akzeptieren" stimmen Sie der Nutzung zu.
        </Text>
        <ButtonGroup>
          <AcceptButton onClick={handleAccept}>Akzeptieren</AcceptButton>
          <DeclineButton onClick={handleDecline}>Ablehnen</DeclineButton>
        </ButtonGroup>
      </BannerContent>
    </Banner>
  );
};

export default CookieConsent;
