import styled from "styled-components";

export const HeroContainer = styled.div`
  padding: 10px 30px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    padding: 10px 20px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 60px;
  @media screen and (max-width: 960px) {
    padding: 0 10px;
  }
`;

export const HeaderText = styled.span`
  color: #001524;
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const SubTextContainer = styled.div`
  padding: 20px 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    text-align: start;
    padding: 20px 20px;
  }
`;

export const SubText = styled.span`
  color: #022c43;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.8;
`;

export const SubTextTwo = styled.span`
  padding-top: 18px;
  color: #022c43;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.8;
`;

export const MainText = styled.span`
  color: #022c43;
  font-size: 18px;
  font-weight: 500;
  padding-top: 5px;
`;

export const LinkText = styled.a`
  color: #024CAA;
`;
