import React from "react";
import {
  MainContainer,
  HeaderText,
  HeaderBoxWrapper,
  BoxListWrapper,
  SubText,
} from "./styles";

const SubDetails = () => {
  return (
    <MainContainer>
      <HeaderBoxWrapper>
        <HeaderText>
          Warum solltest du das 90-Tage-Bodytransformations-Programm wählen?
        </HeaderText>
        <BoxListWrapper>
          <SubText>
            ◉ Individuell abgestimmt auf dich: Jeder Trainings- und
            Ernährungsplan ist auf deine persönlichen Bedürfnisse, Ziele und
            deinen aktuellen Fitnessstand zugeschnitten. Nichts ist von der
            Stange – alles ist maßgeschneidert für deine Transformation.
          </SubText>
          <SubText>
            ◉ Wissenschaftlich fundierte Methoden: Mein Ansatz basiert auf den
            neuesten sport- und ernährungswissenschaftlichen Erkenntnissen, um
            maximale Ergebnisse in kürzester Zeit zu gewährleisten und
            nachhaltig zu etablieren.
          </SubText>
          <SubText>
            ◉ Maximale Ergebnisse in 90 Tagen: Durch gezielte Strategien für
            Fettabbau oder Muskelaufbau wirst du innerhalb von nur 3 Monaten
            sicht- und messbare Veränderungen an deinem Körper erleben.
          </SubText>
          <SubText>
            ◉ Umfassende Betreuung: Du bist nicht allein! Ich begleite dich
            Schritt für Schritt – ob beim Training, in der Ernährung oder wenn
            du mentale Unterstützung brauchst.
          </SubText>
        </BoxListWrapper>
        <HeaderText>Details</HeaderText>
        <BoxListWrapper>
          <SubText>
            Jetzt ist der perfekte Zeitpunkt, um den ersten Schritt in Richtung
            deines neuen Körpers zu gehen. Starte deine 90-Tage-Transformation
            noch heute und entdecke, was du in nur drei Monaten alles erreichen
            kannst. Dein neues Ich wartet schon auf dich!
          </SubText>
        </BoxListWrapper>
      </HeaderBoxWrapper>
    </MainContainer>
  );
};

export default SubDetails;
