import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";
// router //
import { Link } from "react-router-dom";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>BASIC</h4>
          <p>60 Minuten</p>
        </Heading>
        <Price>90 €</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ individueller Trainingsplan </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technik- und Formkorrektur</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/7sIbLpeap6IvfT2bIV">
          Kaufen
        </CtaButton>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>STANDARD</h4>
          <p>10 x</p>
        </Heading>
        <Price>850€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ individueller Trainingsplan </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 2x15 min Videocall-Update pro Monat</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 45 min kostenlose Erstberatung </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Vielfältige Trainingsmethoden </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technik- und Formkorrektur</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{ initialMessage: "🏋🏼‍♀️Personal Trainer STANDARD 10 x 850€" }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>PREMIUM</h4>
          <p>20 x </p>
        </Heading>
        <Price>1.600€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individueller Trainingsplan</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4x15 min Videocall-Update pro Monat</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 60 min kostenlose Erstberatung </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Vielseitige Trainingsmethoden</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technik- und Formkorrektur </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Motivation und Unterstützung </strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{ initialMessage: "🏋🏼‍♀️Personal Trainer PREMIUM 20 x 1600€" }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
