import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>Buche deine erste Online session</HeaderText>
        <SubText>
          Buch jetzt dein erstes Online-Coaching! Egal ob Yoga, Fitness oder
          Schwimmen, hier findest du den oder die richtige Traininer*in für
          dich! Wir helfen dir, dass du die beste Ergebnisse mit unseren
          Online-Coachings erzielst. Wir sind ein starkes Team an deiner Seite
          bis du dein Ziel erreichst.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
