import React from "react";
// Components //
import {
  VideoBlockContainer,
  VideoBlockWrapper,
  VideoBlockTextContainer,
  VideoSearchInput,
  VideoSearchInputWrapper,
  SearchIcon,
  VideoContainer,
  ThumbnailImage,
  ThumbnailText,
  VideoWrapper,
  ThumbnailTextSub,
} from "./styles";
// Import data //
import { trainingVideos } from "../utilities/data";
// link //
import { Link } from "react-router-dom";

const VideoBlock = () => {
  return (
    <VideoBlockContainer>
      <VideoBlockWrapper>
        <VideoBlockTextContainer>Target your muscles</VideoBlockTextContainer>
        <VideoSearchInputWrapper>
          <SearchIcon />
          <VideoSearchInput placeholder="Search for muscle category" />
        </VideoSearchInputWrapper>
      </VideoBlockWrapper>
      <VideoContainer>
        {trainingVideos.map((videoGroup) => (
          <Link
            key={videoGroup.id}
            to={{
              pathname: "/video-play",
            }}
            state={{ videoGroup }}
          >
            <VideoWrapper>
              <ThumbnailImage
                src={videoGroup.thumbnailImg}
                alt={videoGroup.heading}
              />
              <ThumbnailText>{videoGroup.heading}</ThumbnailText>
              <ThumbnailTextSub>{videoGroup.subHeading}</ThumbnailTextSub>
            </VideoWrapper>
          </Link>
        ))}
      </VideoContainer>
    </VideoBlockContainer>
  );
};

export default VideoBlock;
