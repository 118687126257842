import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// Component //
import { Hero, Sub, Offers, Details } from "../../Compoents/Trainning";

const Trainning = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Offers />
      <Details />
      <Footer />
    </>
  );
};

export default Trainning;
