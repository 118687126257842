import React from "react";
// Components //
import { Nav, Footer } from "../../Compoents/Global";
import { Hero, Sub, Profiles, YogaHero } from "../../Compoents/BookNow";

const BookNow = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Profiles />
      {/* <YogaHero />
      <Sub />
      <Profiles /> */}
      <Footer />
    </>
  );
};

export default BookNow;
