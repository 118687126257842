import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 0px 5px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SubTextConatiner = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

export const SubText = styled.span`
  font-size: 26px;
  font-weight: 400;
  text-transform: capitalize;
  @media screen and (max-width: 960px) {
   font-size: 18px;
  }
`;

export const MainTextConatiner = styled.div`
  display: flex;
  width: 100%;
  height: 35%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    height: 15%;
  }
`;

export const MainTextWrapper = styled.div`
  display: flex;
  width: 50%;
  text-align: center;
  @media screen and (max-width: 960px) {
    width: 80%;
  }
`;

export const MainText = styled.span`
  font-size: 120px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1em;
  @media screen and (max-width: 960px) {
    font-size: 60px;
  }
`;


export const BackgroundImg = styled.img`
  margin-top: 10px;
  width: 100%;
  height: 490px;
  object-fit: cover;
`;