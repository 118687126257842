import styled from "styled-components";

export const Container = styled.div`
  height: 70px;
  display: flex;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  @media screen and (max-width: 960px) {
    transition: 0.9s all ease;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin-bottom: 15px;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 15px 30px;
  @media screen and (max-width: 960px) {
    padding: 15px 10px;
  }
`;

export const LogoImg = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
`;

export const DetailsContainer = styled.div<{ mobileMenuVisible: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    position: absolute;
    top: ${({ mobileMenuVisible }) => (mobileMenuVisible ? "70px" : "-100%")};
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 20px 0;
    z-index: 999;
    opacity: ${({ mobileMenuVisible }) => (mobileMenuVisible ? "1" : "0")};
    transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;
    pointer-events: ${({ mobileMenuVisible }) =>
      mobileMenuVisible ? "auto" : "none"};
  }
`;

export const NavItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #8f7b45;
    text-underline-offset: 5px;
  }

  @media screen and (max-width: 960px) {
    padding: 15px 0;
    width: 100%;
    text-align: center;
  }
`;

export const NavItemBookNow = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #8f7b45;
    text-underline-offset: 5px;
  }

  @media screen and (max-width: 960px) {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    display: flex;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Ensure the background color is set */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 200px;

  @media screen and (max-width: 960px) {
    position: static;
    width: 100%;
    box-shadow: none;
  }
`;

export const DropdownItem = styled.div`
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
  display: block;

  &:hover {
    background-color: #0a0a3f;
    color: #fff;
  }

  @media screen and (max-width: 960px) {
    padding: 10px 0;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  border-radius: 100px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #000;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.7;
  color: #000;
  &:hover {
    color: #fff;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    font-size: 30px;
    cursor: pointer;
  }
`;
