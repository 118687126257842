import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";
// router //
import { Link } from "react-router-dom";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>Muskelaufbau</h4>
        </Heading>
        <Price>1.500 €</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 Training-Sessions</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Ernährungspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Trainingspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12x30 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 60 min kostenlose Erstberatung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unbegrenzte Problemlöser-Anrufe</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Motivation und Unterstützung</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{
            initialMessage:
              "🥊 Deine 90-Tage-Bodytransformation Muskelaufbau 1.500 €",
          }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>Gewichtsverlust</h4>
        </Heading>
        <Price>1.500 €</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 Training-Sessions</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Ernährungspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Trainingspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12x30 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 60 min kostenlose Erstberatung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unbegrenzte Problemlöser-Anrufe</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Motivation und Unterstützung</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{
            initialMessage:
              "🥊 Deine 90-Tage-Bodytransformation Gewichtsverlust 1.500 €",
          }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>Fitter werden </h4>
        </Heading>
        <Price>1.500 €</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 Training-Sessions</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Ernährungspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12 individuelle Trainingspläne</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 12x30 min Videocall-Update</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 60 min kostenlose Erstberatung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unbegrenzte Problemlöser-Anrufe</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Motivation und Unterstützung</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{
            initialMessage:
              "🥊 Deine 90-Tage-Bodytransformation Fitter werden 1.500 €",
          }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
