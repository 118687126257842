import React from 'react'
import { Nav, Footer } from "../../Compoents/Global";
import { Main } from "../../Compoents/Form";

const Form = () => {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
    </>
  )
}

export default Form
