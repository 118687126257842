import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 0 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 5px 5px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 75%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
`;

export const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  flex-direction: column;
  color: #022c43;
  @media screen and (max-width: 960px) {
    font-size: 26px;
  }
`;

export const SubText = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  align-self: center;
  color: #022c43;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  height: 38px;
  width: 120px;
  border-radius: 100px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  &:hover {
    background-color: #000;
    color: #fff;
  }
  @media screen and (max-width: 960px) {
    margin-top: 15px;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  cursor: pointer;
`;
