import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>Deine 90-Tage-Bodytransformation</HeaderText>
        <SubText>
          In 90 Tagen wirst du nicht nur deinen Körper transformieren – du wirst
          deine gesamte Einstellung zu Fitness, Ernährung und deinem
          Wohlbefinden neu gestalten. Mein 90-Tage-Bodytransformations-Programm
          bietet dir die perfekte Kombination aus individuell abgestimmtem
          Training, Ernährung und intensiver Betreuung, damit du deine Ziele –
          sei es Abnehmen oder Muskelaufbau – garantiert erreichst.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
