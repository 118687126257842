import styled from "styled-components";

// Container for the whole section //
export const PricingPlans = styled.section`
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 64px;
`;

// Individual pricing card container //
export const PricingCard = styled.div`
  --col: ${(props) => props.color};
  position: relative;
  min-width: 300px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 12px;
  border: 1px solid #262626;
  background-color: #fff;
  box-shadow: 0 0 32px transparent;
  text-align: center;
  &:hover {
    border-color: var(--col);
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    transform: translateY(-16px) scale(1.02);
    transition: all 0.5s ease;
  }
  > *:not(:last-child) {
    margin-bottom: 32px;
  }
  @media screen and (max-width: 960px) {
    min-width: 300px;
  }
`;

// Heading styles //
export const Heading = styled.div`
  h4 {
    padding-bottom: 12px;
    color: var(--col);
    font-size: 24px;
    font-weight: 900;
  }

  p {
    color: #001524;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 600;
  }
`;

// Price styles //
export const Price = styled.p`
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;

  sub {
    position: absolute;
    bottom: 14px;
    color: #a3a3a3;
    font-size: 14px;
    font-weight: lighter;
  }
`;

// Features list styles //
export const Features = styled.ul`
  list-style: none;
  padding: 0;

  li {
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: lighter;
    text-align: left;

    i,
    strong {
      color: #001524;
      opacity: 0.8;
    }

    strong {
      padding-left: 24px;
    }
  }
`;

// Call-to-action button styles //
export const CtaButton = styled.button`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #e4e4e7;
  font-size: 20px;
  font-weight: bold;

  &:active {
    background-color: transparent;
    color: var(--col);
    transition: all 0.3s ease;
  }
`;

// Basic Plan styled component //
export const BasicPlan = styled(PricingCard)`
  --col: #747775;
`;

// Standard Plan styled component //
export const StandardPlan = styled(PricingCard)`
  --col: #0a0a3f;
`;

// Premium Plan styled component //
export const PremiumPlan = styled(PricingCard)`
  --col: #8f7b45;
`;
