import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// Components //
import { Hero, VideoBlock} from "../../Compoents/VideoPage";

const VideoPage = () => {
  return (
    <>
      <Nav />
      <Hero />
      <VideoBlock />
      <Footer />
    </>
  );
};

export default VideoPage;
