import React from "react";
import {
  MainContainer,
  HeaderWrapper,
  HeaderText,
  SubText,
  BtnContainer,
  BtnHeadContainer,
} from "./styles";
// Data source //
import Home from "../../../assets/Data/Home.json";
// icons //
import { FaWhatsapp, FaTelegram } from "react-icons/fa";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>{Home.SubHero.HeaderText}</HeaderText>
        <SubText>{Home.SubHero.SubHeaderText}</SubText>
        <BtnHeadContainer>
          <BtnContainer href="https://api.whatsapp.com/message/UZV5TRD2HPX5D1?autoload=1&app_absent=0">
            <FaWhatsapp size="35" color="#25D366" />
          </BtnContainer>
          <BtnContainer href="https://t.me/joinpuya">
            <FaTelegram size="35" color="#24A1DE" />
          </BtnContainer>
        </BtnHeadContainer>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
