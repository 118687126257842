import React from "react";
import {
  HeroContainer,
  BackgroundVideo,
  HeroContent,
  HeroText,
} from "./styles";

const Hero = () => {
  return (
    <HeroContainer>
      <BackgroundVideo controls={false} playsInline loop autoPlay muted>
        <source src="/video/nintycross.mp4" type="video/mp4" />
      </BackgroundVideo>
      <HeroContent>
        <HeroText>
          Deine 90-Tage-Bodytransformation – Abnehmen, Muskeln aufbauen und ein
          neues Lebensgefühl
        </HeroText>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
