import styled from "styled-components";

export const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const HeroContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroText = styled.h1`
  color: white;
  font-size: 3rem;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 1);
  @media screen and (max-width: 960px) {
    font-size: 2rem;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #8f7b45;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  &:hover {
    border-radius: 20px;
    background-color: #000;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 50px;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;
