import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import Food from "../../../assets/imgs/food.png";

const Hero = () => {
  return (
    <HeroContainer>
      <ImgContainer>
        <Img src={Food} />
      </ImgContainer>
      <HeaderContainer>
        <HeaderText>
          Ernährungsplan – individuell, gesund und nachhaltig
        </HeaderText>
        <SubText>
          Hier findest du mit meiner Unterstützung deinen Weg zu einem
          gesünderen, stärkeren und glücklicheren Leben! Egal, ob du Gewicht
          verlieren, Muskeln aufbauen oder deine Ernährung optimieren möchtest –
          gemeinsam werden wir deinen individuellen Ernährungsplan für dich
          zusammenstellen, um deine Ziele zu erreichen.Meine Ernährungsberatung
          beschleunigt den Weg zu deinem Ziel und bringt dich Schritt für
          Schritt deinem Traumkörper näher. Melde dich noch heute bei mir und
          lass uns ein erstes unverbindliches Beratungsgespräch vereinbaren!
          Selbstverständlich berücksichtige ich deine Wünsche bei der
          Planerstellung.In meinem Ernährungscoaching erhältst du individuell
          abgestimmte Ernährungspläne – ob zum Abnehmen, Zunehmen, Muskelaufbau
          oder für eine vegane, vegetarische oder kalorienarme Ernährung.
        </SubText>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Hero;
