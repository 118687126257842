import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>Fit-bleiben-Trainingsplan</h4>
          <p>1 Monat</p>
        </Heading>
        <Price>50€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individueller Trainingsplan </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 2x15 min Videocall-Update </strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/14keXBaYd4An4ak14l">
          Kaufen
        </CtaButton>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>Muskelaufbau-Trainingsplan</h4>
          <p>1 Monat</p>
        </Heading>
        <Price>90€ </Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individueller Trainingsplan </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 45 min kostenlose Erstberatung </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1 Problemlöser-Anruf</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/bIY6r56HXd6TfT24gy">
          Kaufen
        </CtaButton>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>Gewichtsverlust-Trainingsplan</h4>
          <p>1 Monat</p>
        </Heading>
        <Price>90€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 2 Körperanalysen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individueller Trainingsplan </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 4x15 min Videocall-Update </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Unterstützung via E-Mail und WhatsApp</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 45 min kostenlose Erstberatung</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1 Problemlöser-Anruf </strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/dR602Hfet2sf36gdR9">
          Kaufen
        </CtaButton>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
