import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import Trip from "../../../assets/imgs/aboutImgTwo.png";

const Sub = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>Eine schwere Zeit in Deutschland</HeaderText>
        <SubText>
          Hinter mir liegen einige schwere Jahre, in denen ich mit Sport nicht
          viel am Hut hatte. Ich wollte mir ein neues Leben in Deutschland
          aufbauen und habe daher angefangen, als Vertriebsaußendienst in einer
          Firma zu arbeiten. In den 7 Jahren, in denen ich diesen Job ausübte,
          habe ich fast 30 Kilo zugenommen und diverse gesundheitliche Probleme
          bekommen. Ich bin ab und an ins Fitnessstudio gegangen,habe aber nie
          mit Disziplin trainiert, da ich meine sportlichen Ziele aus den Augen
          verloren habe.
        </SubText>
      </HeaderContainer>
      <ImgContainer>
        <Img src={Trip} />
      </ImgContainer>
    </HeroContainer>
  );
};

export default Sub;
