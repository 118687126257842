import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

export const HeaderText = styled.span`
  color: #001524;
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const SubText = styled.span`
  color: #001524;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  margin-bottom: 20px;
  @media screen and (max-width: 960px) {
    font-size: 15px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1.5rem;
  margin-bottom: 20px;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: none;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const SilderSelectedText = styled.span`
  font-size: 22px;
  font-weight: 800;
  color: #fa4032;
`;

export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease-in-out;
  margin: 10px 0;
  &:hover {
    background: #ccc;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #8f7b45;
    border-radius: 10%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }
  &::-webkit-slider-thumb:hover {
    background: #0a0a3f;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #8f7b45;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }
  &::-moz-range-thumb:hover {
    background: #0056b3;
  }
  &::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #007bff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }
  &::-ms-thumb:hover {
    background: #0056b3;
  }
  &::-ms-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
`;

export const BtnContainer = styled.button`
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #000;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  @media screen and (max-width: 960px) {
    margin-bottom: 5px;
  }
`;

export const BtnContainerBox = styled.button`
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #000;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media screen and (max-width: 960px) {
    margin-bottom: 5px;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;

export const Box = styled.div`
  width: calc(33.3% - 20px);
  height: 120%;
  margin-bottom: 20px;
  padding: 12px 12px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const Img = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
`;

export const HeadingTextContainer = styled.div`
  padding: 8px 12px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    height: 10%;
  }
`;

export const HeaderTextBox = styled.span`
  color: #001524;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    font-weight: 900;
    margin-top: 10px;
    line-height: 40px;
    letter-spacing: 0.8px;
  }
`;

export const Expirense = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.8;
  margin-top: 5px;
  letter-spacing: 0.9px;
  line-height: 20px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const HighLight = styled.span`
  color: #ef5a6f;
  font-size: 15px;
  font-weight: 900;
  margin-top: 5px;
  letter-spacing: 0.9px;
  line-height: 20px;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

export const BtnContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
