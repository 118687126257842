import React from "react";
import {
  FeaturesContainer,
  HeaderText,
  BoxContainer,
  Box,
  Images,
  Spans,
} from "./styles";
// imgs //
import sub from "../../../assets/imgs/sub.png";
import sub3 from "../../../assets/imgs/sub3.png";
import swim from "../../../assets/imgs/swim.png";
import online from "../../../assets/imgs/online.png";
import Pro from "../../../assets/imgs/proMe.png";
// router //
import { Link } from "react-router-dom";
// Data source //
import Home from "../../../assets/Data/Home.json";

const Scroll = () => {
  return (
    <FeaturesContainer>
      <HeaderText>{Home.ScrollingSection.HeaderText}</HeaderText>
      <BoxContainer>
        <Box>
          <Link to="/training-plan">
            <Images src={sub} alt="trainings-plan" />
            <Spans>{Home.ScrollingSection.CardOne}</Spans>
          </Link>
        </Box>
        <Box>
          <Link to="/personal-training">
            <Images src={online} alt="personal-trainer" />
            <Spans>{Home.ScrollingSection.CardTwo}</Spans>
          </Link>
        </Box>
        <Box>
          <Link to="/schwimmen">
            <Images src={swim} alt="swimming-training" />
            <Spans>{Home.ScrollingSection.CardThree}</Spans>
          </Link>
        </Box>
        <Box>
          <Link to="/neunzig-tage-transformation">
            <Images src={Pro} alt="transformation" />
            <Spans>{Home.ScrollingSection.CardFour}</Spans>
          </Link>
        </Box>
        <Box>
          <Link to="/ernahrungsplan">
            <Images src={sub3} alt="nutrition-plan" />
            <Spans>{Home.ScrollingSection.CardFive}</Spans>
          </Link>
        </Box>
      </BoxContainer>
    </FeaturesContainer>
  );
};

export default Scroll;
