import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// compoents //
import {  Hero, Sub, Offers  } from "../../Compoents/Skids";

const SKids = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Offers />
      <Footer />
    </>
  );
};

export default SKids;
