import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
import { Main } from "../../Compoents/Datenschutz";

const Datenshutz = () => {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
    </>
  );
};

export default Datenshutz;
