import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  BtnContainer,
  BtnText,
  ImgContainer,
  Img,
} from "./styles";
import SBeginner from "../../../assets/imgs/sbeginner.png";

const Hero = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>
          Empower Your Journey: Learn from Expert Trainner!
        </HeaderText>
        <SubText>
          One of the greatest challenges in maintaining a fitness routine is
          staying motivated. Regular exercise has been shown to improve mental
          health, and having a personal trainer can enhance these benefits.
        </SubText>
        <BtnContainer>
          <BtnText>Join today!</BtnText>
        </BtnContainer>
      </HeaderContainer>
      <ImgContainer>
        <Img src={SBeginner} />
      </ImgContainer>
    </HeroContainer>
  );
};

export default Hero;
