import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// components //
import { Hero, HeroTwo, Sub, SubTwo, Contact } from "../../Compoents/About";

const About = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <HeroTwo />
      <SubTwo />
      <Contact />
      <Footer />
    </>
  );
};

export default About;
