import React from "react";
// Components //
import { Nav, Footer } from "../../Compoents/Global";
import { Main } from "../../Compoents/Impressum";
const Impressum = () => {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
    </>
  );
};

export default Impressum;
