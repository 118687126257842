import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 0px 5px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SubTextConatiner = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

export const SubText = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
`;

export const MainTextConatiner = styled.div`
  display: flex;
  width: 100%;
  height: 35%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    height: 15%;
  }
`;

export const MainTextWrapper = styled.div`
  display: flex;
  width: 50%;
  text-align: center;
  @media screen and (max-width: 960px) {
    width: 80%;
  }
`;

export const MainText = styled.span`
  font-size: 120px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1em;
  @media screen and (max-width: 960px) {
    font-size: 60px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  padding-top: 15px;
  justify-content: center;
  @media screen and (max-width: 960px) {
    height: 50%;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  padding-top: 10px;
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  outline: none;
  &:focus {
    outline: 1px solid;
  }
`;

export const IconConatiner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const IconImg = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
   margin-top: 26px;
  @media screen and (max-width: 960px) {
    margin-top: 20px;
    width: 46px;
    height: 46px;
  }
`;
