export const muscleData = [
  {
    id: 1,
    name: "Bicep",
    muscle_group: "Arm",
    variations: [
      {
        name: "Hammer Curls",
        sets: 3,
        reps: 12,
        video: "/video/hm2.mp4",
      },
      {
        name: "Bicep Curls",
        sets: 3,
        reps: 12,
        video: "/video/hm1.mp4",
      },
      {
        name: "EZ Bar curls",
        sets: 3,
        reps: 12,
        video: "/video/hm.mp4",
      },
      {
        name: "Preacher curl",
        sets: 3,
        reps: 12,
        video: "/video/hm5.mp4",
      },
    ],
  },
  {
    id: 2,
    name: "Chest",
    muscle_group: "Chest",
    variations: [
      {
        name: "Incline dumbbell press",
        sets: 4,
        reps: 10,
        video: "/video/packWorkOne.mp4",
      },
      {
        name: "Close grip incline bench press",
        sets: 3,
        reps: 12,
        video: "/video/packWorkTwo.mp4",
      },
      {
        name: "Butterfly press",
        sets: 3,
        reps: 12,
        video: "/video/packWorkThree.mp4",
      },
      {
        name: "Deeps",
        sets: 3,
        reps: 12,
        video: "/video/packWorkFour.mp4",
      },
    ],
  },
  {
    id: 3,
    name: "Shoulder",
    muscle_group: "Shoulders",
    variations: [
      {
        name: "Landmine press",
        sets: 4,
        reps: 10,
        video: "/video/shoulderWorkOne.mp4",
      },
      {
        name: "Shoulder front raise cable",
        sets: 3,
        reps: 12,
        video: "/video/shoulderWorkTwo.mp4",
      },
      {
        name: "Shoulder raise lateral",
        sets: 3,
        reps: 12,
        video: "/video/shoulderWorkThree.mp4",
      },
      {
        name: "Pull ups",
        sets: 3,
        reps: 12,
        video: "/video/shoulderWorkFour.mp4",
      },
    ],
  },
  {
    id: 4,
    name: "Back",
    muscle_group: "Back",
    variations: [
      {
        name: "Deadlifts",
        sets: 4,
        reps: 8,
        video: "/video/hero.mp4",
      },
      {
        name: "Pull-ups",
        sets: 3,
        reps: 20,
        video: "/video/hero.mp4",
      },
      {
        name: "Barbell Rows",
        sets: 3,
        reps: 10,
        video: "/video/hero.mp4",
      },
      {
        name: "Lat Pulldowns",
        sets: 3,
        reps: 12,
        video: "/video/hero.mp4",
      },
    ],
  },
  {
    id: 5,
    name: "Legs",
    muscle_group: "Legs",
    variations: [
      {
        name: "Squats",
        sets: 4,
        reps: 10,
        video: "/video/legWorkOne.mp4",
      },
      {
        name: "Lounge",
        sets: 3,
        reps: 8,
        video: "/video/legWorkTwo.mp4",
      },
      {
        name: "Cross Lunge",
        sets: 3,
        reps: 12,
        video: "/video/legWorkThree.mp4",
      },
      {
        name: "Goblet squats",
        sets: 3,
        reps: 10,
        video: "/video/legWorkFour.mp4",
      },
    ],
  },
  {
    id: 6,
    name: "Abs",
    muscle_group: "Abdominals",
    variations: [
      {
        name: "Mountain Climbers",
        sets: 3,
        reps: 15,
        video: "/video/absWorkOne.mp4",
      },
      {
        name: "Russian twists",
        sets: 3,
        duration: 10,
        video: "/video/absWorkTwo.mp4",
      },
      {
        name: "V-Up",
        sets: 3,
        reps: 12,
        video: "/video/absWorkThree.mp4",
      },
      {
        name: "Kettlebell",
        sets: 3,
        reps: 12,
        video: "/video/absWorkFour.mp4",
      },
    ],
  },
  {
    id: 7,
    name: "Tricep",
    muscle_group: "Arm",
    variations: [
      {
        name: "Kickback triceps",
        sets: 3,
        reps: 12,
        video: "/video/tricepWorkOne.mp4",
      },
      {
        name: "Triceps cable pushdown",
        sets: 3,
        reps: 12,
        video: "/video/tricepWorkTwo.mp4",
      },
      {
        name: "Arnold Dips",
        sets: 3,
        reps: 12,
        video: "/video/tricepWorkThree.mp4",
      },
      {
        name: "Overhead dumbbell tricep",
        sets: 3,
        reps: 12,
        video: "/video/tricepWorkFour.mp4",
      },
    ],
  },
  // Other muscles //
];
