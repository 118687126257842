/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useRef, useEffect } from "react";
import {
  VideoPlayContainer,
  VideoPlayWrapper,
  VideoList,
  VideoContainer,
  VideoWrapper,
  Video,
  VideoTextBottomContainer,
  VideoHeadingText,
  VideoHeadingSub,
  VideoListContainer,
  VideoBox,
  ListHeadingText,
  ListVideo,
  VideoHeadingList,
  VideoHeadingListSub,
} from "./styles";

interface Video {
  name: string;
  subHeading: string;
  video: string;
}

interface VideoGroup {
  id: number;
  thumbnailImg: string;
  heading: string;
  subHeading: string;
  video: Video[];
}

interface MainProps {
  item: VideoGroup | null;
}

const Main: React.FC<MainProps> = ({ item }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const currentVideo = item?.video[currentVideoIndex];
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current && currentVideo?.video) {
      const handleCanPlay = () => {
        videoRef.current?.play();
      };

      videoRef.current.addEventListener("canplay", handleCanPlay);

      // Load the new video source //
      videoRef.current.src = currentVideo.video;
      videoRef.current.load();

      // Cleanup //
      return () => {
        videoRef.current?.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [currentVideoIndex, currentVideo?.video]);

  return (
    <VideoPlayContainer>
      <VideoPlayWrapper>
        <VideoContainer>
          <VideoWrapper>
            <Video controls playsInline loop muted ref={videoRef}>
              <source src={currentVideo?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </VideoWrapper>
        </VideoContainer>
        <VideoTextBottomContainer>
          <VideoHeadingText>{currentVideo?.name}</VideoHeadingText>
          <VideoHeadingSub>{currentVideo?.subHeading}</VideoHeadingSub>
        </VideoTextBottomContainer>
      </VideoPlayWrapper>
      <VideoList>
        <ListHeadingText>More videos:</ListHeadingText>
        {item?.video.map((video, index) => (
          <VideoListContainer
            key={index}
            onClick={() => setCurrentVideoIndex(index)}
            style={{
              backgroundColor:
                index === currentVideoIndex ? "#FF8A8A" : "transparent",
              borderRadius: "12px",
            }}
          >
            <VideoBox>
                <ListVideo src={video.video} />
              <VideoTextBottomContainer>
                <VideoHeadingList>{video.name}</VideoHeadingList>
                <VideoHeadingListSub>{video.subHeading}</VideoHeadingListSub>
              </VideoTextBottomContainer>
            </VideoBox>
          </VideoListContainer>
        ))}
      </VideoList>
    </VideoPlayContainer>
  );
};

export default Main;
