import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// Compoents //
import {
  FormContainer,
  HeaderText,
  StyledForm,
  FormGroup,
  StyledLabel,
  StyledLabelBox,
  StyledInput,
  StyledTextarea,
  BtnContainer,
  BtnText,
} from "./styles";
// email //
import emailjs from "emailjs-com";
// local state //
interface LocationState {
  initialMessage?: string;
}

const Main: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  // Local state //
  const location = useLocation();
  const initialMessage =
    (location.state as LocationState)?.initialMessage || "";

  // Send email //
  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_9l09ekb",
          "template_q6zbzrs",
          form.current,
          "OGMi62H3186xNKfo4"
        )
        .then(
          (result) => {
            alert("Thank you! we reach you soon!");
          },
          (error) => {
            alert("Failed to send the message. Please try again later.");
          }
        );
      form.current.reset();
      setIsPrivacyChecked(false);
    }
  };

  return (
    <FormContainer>
      <HeaderText>Kontakt</HeaderText>
      <StyledForm ref={form} onSubmit={sendEmail}>
        <StyledInput type="hidden" name="subject" value={initialMessage} />
        <FormGroup>
          <StyledLabel htmlFor="name">Vollständiger Name:</StyledLabel>
          <StyledInput type="text" id="name" name="name" required />
        </FormGroup>
        <FormGroup>
          <StyledLabel htmlFor="email">Email:</StyledLabel>
          <StyledInput type="email" id="email" name="email" required />
        </FormGroup>
        <FormGroup>
          <StyledLabel htmlFor="phone">Telefonnummer:</StyledLabel>
          <StyledInput type="text" id="phone" name="phone" required />
        </FormGroup>
        <FormGroup>
          <StyledLabel htmlFor="message">Nachricht:</StyledLabel>
          <StyledTextarea id="message" name="message" required />
        </FormGroup>
        <FormGroup>
          <StyledLabelBox>
            <StyledInput
              type="checkbox"
              checked={isPrivacyChecked}
              onChange={() => setIsPrivacyChecked(!isPrivacyChecked)}
              required
            />{" "}
            Ich stimme den Datenschutz zu
          </StyledLabelBox>
        </FormGroup>
        <BtnContainer type="submit">
          <BtnText>Absenden</BtnText>
        </BtnContainer>
      </StyledForm>
    </FormContainer>
  );
};

export default Main;
