import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 90px 40px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 25px 5px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
`;

export const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  flex-direction: column;
  color: #001524;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 26px;
  }
`;

export const SubText = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  align-self: center;
  color: #022c43;
  opacity: 0.8;
  line-height: 1.8rem;
  width: 80%;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
`;
