import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

export const HeaderText = styled.span`
  color: #001524;
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const SubText = styled.span`
  color: #001524;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  margin-bottom: 20px;
  @media screen and (max-width: 960px) {
    font-size: 15px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1.5rem;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const StyledLabelBox = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 30px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const StyledTextarea = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  height: 120px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const BtnContainer = styled.button`
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #000;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media screen and (max-width: 960px) {
    margin-bottom: 50px;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;
