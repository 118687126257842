import React from "react";
import {
  HeroContainer,
  BackgroundVideo,
  HeroContent,
  HeroText,
} from "./styles";

const Hero = () => {
  return (
    <HeroContainer>
      <BackgroundVideo controls={false} playsInline loop autoPlay muted>
        <source src="/video/planTrain.mp4" type="video/mp4" />
        Your browser does not support the video tag.
        <a href="/video/planTrain.mp4">Download the video instead.</a>
      </BackgroundVideo>
      <HeroContent>
        <HeroText>Intensiv-Workshop: Stärke aufbauen</HeroText>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
