import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  BtnContainer,
  BtnText,
  ImgContainer,
  Img,
} from "./styles";
import SKid from "../../../assets/imgs/Skid.png";

const Hero = () => {
  return (
    <HeroContainer>
      <ImgContainer>
        <Img src={SKid} />
      </ImgContainer>
      <HeaderContainer>
        <HeaderText>
          Fuel Your Body: The Power of Balanced Nutrition!
        </HeaderText>
        <SubText>
          Balanced nutrition is the key to fueling your body effectively,
          providing the essential nutrients needed for energy, growth, and
          overall well-being. It involves consuming a variety of foods that
          supply proteins, carbohydrates, fats, vitamins, and minerals in the
          right proportions.
        </SubText>
        <BtnContainer>
          <BtnText>Join today!</BtnText>
        </BtnContainer>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Hero;
