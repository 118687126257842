import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  ImgContainer,
  Img,
} from "./styles";
import Trip from "../../../assets/imgs/aboutImgFour.png";

const SubTwo = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>Was ist mein Ziel</HeaderText>
        <SubText>
          Ich habe festgestellt, dass es in Berlin ein großes Defizit an
          Schwimmlehre gibt. Zusätzlich haben besonders Kinder in den
          Corona-Jahren oft keinen Schwimmunterricht erhalten. Joinpuya hilft
          dir dabei, deine Schwimmträume zu verwirklichen. Ich unterrichte
          Kinder ab 4 Jahren, aber auch Senioren und Seniorinnen. Ich helfe dir
          mit viel Geduld dabei, dass du gründlich und ohne Angst Schwimmen
          lernst oder deine Technik verbesserst. Meine zweite Leidenschaft ist
          der Fitnesssport - hier habe ich auch diverse Angebote für dich. Von
          Privatunterricht bis Online-Coaching, Ernährungsplan oder
          Bodytransformation: Ich werde dich täglich begleiten und mit dir
          Schritt für Schritt alle Hürden nehmen. Wenn du tatsächlich etwas in
          deinem Leben ändern möchtest, melde dich bei mir und lass und
          gemeinsam deine Fitnessreise starten,um deine Ziele zu erreichen.
        </SubText>
      </HeaderContainer>
      <ImgContainer>
        <Img src={Trip} />
      </ImgContainer>
    </HeroContainer>
  );
};

export default SubTwo;
