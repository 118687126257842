import React from "react";
// Components //
import { Nav, Footer } from "../../Compoents/Global";
import { Sub, Discounts } from "../../Compoents/Offers";

const Offers = () => {
  return (
    <>
      <Nav />
      <Discounts />
      <Sub />
      <Footer />
    </>
  );
};

export default Offers;
