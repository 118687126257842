import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>BASIC</h4>
          <p>60 minutes</p>
        </Heading>
        <Price>
          80€
          <sub>/one time</sub>
        </Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Diverse training methods</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1:1 training plan</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individual fitness assessment</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technique and form correction</strong>
          </li>
        </Features>
        <CtaButton>SELECT</CtaButton>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>STANDARD</h4>
          <p>10 x</p>
        </Heading>
        <Price>
          700 €<sub>/month</sub>
        </Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1 hour free Consultation </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Diverse training methods</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1:1 training plan</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Individual fitness assessment</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technique and form correction</strong>
          </li>
        </Features>
        <CtaButton>SELECT</CtaButton>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>PREMIUM</h4>
          <p>20 x </p>
        </Heading>
        <Price>
          1,400€
          <sub>/month</sub>
        </Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 2 hours free consultation</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ weekly video call</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ individual training plan</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technique and form correction</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Motivation and support</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Goal tracking and progress evaluation</strong>
          </li>
        </Features>
        <CtaButton>SELECT</CtaButton>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
