import React from "react";
import {
  ContainerBox,
  CenterText,
  TextDicriptionContainer,
  DiscriptionNum,
  ContentWrapper,
  TitleText,
  DicriptionTextWrapper,
  DiscriptionText,
} from "./styles";

const Details = () => {
  return (
    <ContainerBox>
      <CenterText>
        Maßgeschneiderte 1:1 Trainingspläne – dein Schlüssel zu optimalen
        Ergebnissen
      </CenterText>
      <TextDicriptionContainer>
        <DiscriptionNum>01</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Abnehmen</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Gezielter Fettabbau erfordert mehr als nur Kalorienverbrauch. Mein
              1:1 Trainingsansatz integriert hochintensive Intervalleinheiten
              (HIIT), gezieltes Krafttraining und Stoffwechselanpassungen, die
              die Fettverbrennung maximieren und den Erhalt von Muskelmasse
              fördern. Durch eine individuell angepasste Trainingsfrequenz sowie
              diverser Progressionsmethoden wird dein Stoffwechsel dauerhaft
              angekurbelt.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>02</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Muskelaufbau</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Für optimalen Muskelaufbau ist das Zusammenspiel aus mechanischer
              Spannung, metabolischem Stress und gezieltem Volumen entscheidend.
              Mein Trainingsplan basiert auf progressiver Überlastung,
              periodisierten Zyklen und einer präzisen Balance aus Hypertrophie-
              und Krafttraining, um die maximale Proteinsynthese zu fördern. Du
              wirst nicht nur Muskeln aufbauen, sondern deine gesamte
              körperliche Verfassung verbessern.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>03</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Fit und gesund werden</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Gesundheit und Fitness gehen Hand in Hand. Mein Ansatz setzt auf
              funktionelles Training, das deine Beweglichkeit, Stabilität und
              Ausdauer stärkt, während dein Körper von der erhöhten
              kardiorespiratorischen Kapazität profitiert. Durch individuell
              zugeschnittene Übungen optimieren wir deine
              Herz-Kreislauf-Gesundheit und fördern langfristige Vitalität.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>04</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Warum meine 1:1 Trainingspläne?</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              ◉ Individuell abgestimmt: Jeder Plan wird speziell für dich und
              deine Ziele erstellt – keine Standardlösungen.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Wissenschaftlich fundiert: Jedes Training basiert auf aktuellen
              sportwissenschaftlichen Prinzipien wie progressiver Überlastung,
              Periodisierung und metabolischer Anpassung.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Maximale Ergebnisse: Mit maßgeschneiderten Strategien erreichst
              du deine Ziele schneller und mit nachhaltigen Erfolgen.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Kontinuierliche Betreuung: Du profitierst von einer 1:1
              Betreuung, die sicherstellt, dass dein Training optimal an deine
              Fortschritte angepasst wird.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Starte jetzt deinen Weg zu einem fitteren, stärkeren und
              gesünderen Körper – lass uns gemeinsam deine Ziele erreichen!
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>
    </ContainerBox>
  );
};

export default Details;
