import React from "react";
import {
  ContainerBox,
  TextDicriptionContainer,
  DiscriptionNum,
  ContentWrapper,
  TitleText,
  DicriptionTextWrapper,
  DiscriptionText,
} from "./styles";

const Details = () => {
  return (
    <ContainerBox>
      <TextDicriptionContainer>
        <DiscriptionNum>01</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Abnehmen</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Ein effektiver und nachhaltiger Gewichtsverlust erfordert mehr als nur
              Kaloriendefizite. Meine Ernährungspläne sind so konzipiert, dass
              dein Körper optimal mit Nährstoffen versorgt wird, während wir
              gleichzeitig die Fettverbrennung fördern und den Muskelabbau
              verhindern. Durch den gezielten Einsatz von Makronährstoffen und
              einer angepassten Mahlzeitenfrequenz unterstützen wir den
              Stoffwechsel und maximieren die Fettverbrennung.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>02</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Muskelaufbau</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Beim Muskelaufbau geht es um mehr als nur Proteinzufuhr. Meine
              Pläne basieren auf einer ausgewogenen Kombination aus hochwertigen
              Proteinen, komplexen Kohlenhydraten und gesunden Fetten, um den
              anabolen Stoffwechselprozess zu unterstützen. Durch die
              Bereitstellung der richtigen Nährstoffe zur richtigen Zeit
              optimieren wir die Proteinsynthese und sorgen für ein
              kontinuierliches Muskelwachstum.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>03</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Zunehmen</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              Gesunde Gewichtszunahme erfordert eine gezielte Kalorienzufuhr,
              die deinen Stoffwechsel anregt, ohne überschüssiges Fett zu
              speichern. Mit meinem Plan wirst du nicht nur an Gewicht, sondern
              vor allem an Qualität zunehmen. Wir setzen auf nährstoffdichte
              Lebensmittel und eine perfekte Makronährstoffverteilung, um dir
              nachhaltig mehr Energie und Kraft zu verleihen.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>

      <TextDicriptionContainer>
        <DiscriptionNum>04</DiscriptionNum>
        <ContentWrapper>
          <TitleText>Warum meine Ernährungspläne?</TitleText>
          <DicriptionTextWrapper>
            <DiscriptionText>
              ◉ Individuell abgestimmt: Keine Pauschallösungen – jeder Plan wird
              basierend auf deinem Lebensstil, deinen Zielen und deinem
              Körperbau erstellt.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Wissenschaftlich fundiert: Jeder Plan basiert auf neuesten
              Erkenntnissen der Ernährungs- und Sportwissenschaft.
            </DiscriptionText>
            <DiscriptionText>
              ◉ Nachhaltige Erfolge: Du erreichst nicht nur kurzfristige
              Resultate, sondern lernst, deinen Körper langfristig in Balance zu
              halten.
            </DiscriptionText>
          </DicriptionTextWrapper>
        </ContentWrapper>
      </TextDicriptionContainer>
    </ContainerBox>
  );
};

export default Details;
