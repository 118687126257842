import React from "react";
import { Nav, Footer } from "../../Compoents/Global";
// Components //
import {
  Hero,
  Sub,
  Offers,
  Details,
  SubDetails,
} from "../../Compoents/DaysTransForm";

const DaysTransForm = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Sub />
      <Offers />
      <Details />
      <SubDetails />
      <Footer />
    </>
  );
};

export default DaysTransForm;
