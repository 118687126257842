import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  VideoContainer,
  VideoWrapper,
  Video,
} from "./styles";

const Hero = () => {
  return (
    <HeroContainer>
      <VideoContainer>
        <VideoWrapper>
          <Video controls={false} playsInline loop autoPlay muted>
            <source src="/video/hm1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </VideoWrapper>
      </VideoContainer>
      <HeaderContainer>
        <HeaderText>Start your journey!</HeaderText>
        <SubText>
          Dive into a world of curated workout videos, expert tips, and
          motivational content tailored to help you reach your fitness goals.
          Stay inspired, track your progress, and join a community dedicated to
          health and wellness.
        </SubText>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Hero;
