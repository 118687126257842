import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>FITNESS SHOULD BE ACCESSIBLE TO EVERYONE.</HeaderText>
        <SubText>
          In personal training, I am at your side with all my expertise. Whether
          you want to lose weight, build muscle or improve your endurance - I
          will create a training plan that is tailored precisely to your needs
          and goals. With personal support and continuous motivation, I will
          help you achieve your fitness goals.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
