import React from "react";
import {
  HeroContainer,
  BackgroundVideo,
  HeroContent,
  HeroText,
} from "./styles";

const Hero = () => {
  return (
    <HeroContainer>
      <BackgroundVideo controls={false} playsInline loop autoPlay muted>
        <source src="/video/sAdvance.mp4" type="video/mp4" />
      </BackgroundVideo>
      <HeroContent>
        <HeroText>
          Schwimmen lernen. Technik perfektionieren. Ziele erreichen
        </HeroText>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
