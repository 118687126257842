import React from "react";
import {
  MainContainer,
  MainWrapper,
  MainTextConatiner,
  MainTextWrapper,
  MainText,
  VideoContainer,
  VideoWrapper,
  Video,
  IconConatiner,
  IconImg,
} from "./styles";
// Data source //
import Home from "../../../assets/Data/Home.json";
// icon //
import Icon from "../../../assets/imgs/icon.png";

const Hero = () => {
  return (
    <MainContainer>
      <MainWrapper>
        <MainTextConatiner>
          <MainTextWrapper>
            <MainText>{Home.Hero.MainText}</MainText>
          </MainTextWrapper>
        </MainTextConatiner>
        <VideoContainer>
          <VideoWrapper>
            <Video controls={false} playsInline loop autoPlay muted>
              <source src={Home.Hero.VideoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </VideoWrapper>
        </VideoContainer>
        <IconConatiner>
          <IconImg src={Icon} alt="logo" />
        </IconConatiner>
      </MainWrapper>
    </MainContainer>
  );
};

export default Hero;
