import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    order: 2;
    padding: 20px 5px;
  }
`;

export const MainTextContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100vh;
  justify-content: center;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const TextContainerBox = styled.div`
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const MainText = styled.span`
  font-size: 82px;
  font-weight: bold;
  opacity: 0.9;
`;

export const SubText = styled.span`
  font-size: 28px;
  font-weight: 600;
  margin-top: 12px;
  color: #8f7b45;
`;

export const MuscleTextSelectedContainer = styled.div`
  margin-top: 60px;
  @media screen and (max-width: 960px) {
    margin-top: 10px;
    padding-left: 0px;
  }
`;

export const MuscleText = styled.span`
  margin-top: 60px;
  font-size: 52px;
  font-weight: bold;
  opacity: 0.9;
`;

export const MainImgContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  //! hover effect //
  & > svg:hover {
    cursor: pointer;
    transition: fill 0.3s ease;
  }
  & > svg path:hover {
    fill: #ff4848;
    transition: fill 0.3s ease;
  }
  //! hover effect //
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 50vh;
  }
`;

export const BodySvg = styled.svg`
  height: 600px;
  width: 600px;
  @media screen and (max-width: 960px) {
    width: 300px;
    height: 300px;
  }
`;

// Utility styles //
export const ListContainer = styled.div`
  width: 100%;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0px 10px;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    padding: 0px 0px;
  }
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50.33% - 20px);
  margin-bottom: 20px;
  height: 260px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const ListImg = styled.video`
  width: 100%;
  height: 500px;
`;

export const HeaderWorkoutText = styled.span`
  font-size: 28px;
  font-weight: 600;
  margin-top: 12px;
  color: #ff7d1a;
`;

export const HeaderWorkoutSets = styled.span`
  font-size: 18px;
  font-weight: bold;
  opacity: 0.9;
  margin-top: 12px;
`;

export const BtnContainer = styled.a`
  display: flex;
  width: 140px;
  height: 40px;
  background: #ff0000;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  slef-align: center;
  text-decoration: none;
  &:hover {
    background: #282828;
  }
`;

export const BtnText = styled.span`
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
`;

export const DownloadSectionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0 30px;
  height: 100px;
  background: transparent;
  margin-top: 5%;
  border-radius: 18px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 80%;
  margin-bottom: 30px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-top: 10%;
    height: 120px;
    justify-content: space-evenly;
  }
`;

export const DownloadText = styled.span`
  color: #fff;
  font-size: 32px;
  font-weight: 800;
  @media screen and (max-width: 960px) {
    display: flex;
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    text-align: center;
    justify-content: center;
  }
`;

export const BtnContainerDownload = styled.a`
  display: flex;
  width: 120px;
  height: 40px;
  background: #ff0000;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    width: 120px;
    height: 40px;
  }
`;

export const BtnTextDownload = styled.h3`
  color: #fff;
  font-size: 18px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

export const VideoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 85%;
  justify-content: center;
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
  &:focus {
    outline: 1px solid;
  }
`;

export const VideoTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-text: center;
  padding: 10px;
`;

export const VideoText = styled.span`
  font-size: 18px;
  font-weight: 600;
  opacity: 0.8;
`;

export const SubBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
