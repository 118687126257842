// styles.js
import styled from "styled-components";

export const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 1em;
  @media screen and (max-width: 960px) {
    padding: 0px 25px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  @media (min-width: 600px) {
    margin-top: 0;
  }
`;

export const AcceptButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    background-color: #0056b3;
  }
`;

export const DeclineButton = styled.button`
  padding: 10px 20px;
  background-color: #888;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #555;
  }
`;
