import React from "react";
// components //
import {
  PricingPlans,
  BasicPlan,
  StandardPlan,
  PremiumPlan,
  Heading,
  Price,
  Features,
  CtaButton,
} from "./styles";
// router //
import { Link } from "react-router-dom";

const Offers = () => {
  return (
    <PricingPlans>
      <BasicPlan>
        <Heading>
          <h4>BASIC</h4>
          <p>60 Min</p>
        </Heading>
        <Price>
          80€
          <sub>/one time</sub>
        </Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1:1 Training</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Grundlegende Schwimmtechniken </strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Angstreduktion</strong>
          </li>
        </Features>
        <CtaButton href="https://buy.stripe.com/14k02H6HX3wjdKUcN6">Kaufen</CtaButton>
      </BasicPlan>
      <StandardPlan>
        <Heading>
          <h4>STANDARD</h4>
          <p>5 Stunden Unterricht</p>
        </Heading>
        <Price>375€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1:1 Unterricht</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Angstreduktion</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technikanalyse und -korrektur</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Grundlegende Schwimmtechniken</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Beherrschung einer Schwimmart</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{
            initialMessage:
              "🏊🏻 Deine Swimming STANDARD 5 Stunde Unterricht 375€",
          }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </StandardPlan>
      <PremiumPlan>
        <Heading>
          <h4>PREMIUM</h4>
          <p>10 Stunden Unterricht</p>
        </Heading>
        <Price>700€</Price>
        <Features>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 30 min kostenloses Probetraining</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ 1:1 Unterricht</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Angstreduktion</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Technikanalyse mit Unterwasseraufnahmen</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ grundlegende Schwimmtechniken</strong>
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            <strong>✔️ Beherrschung von zwei Schwimmarten</strong>
          </li>
        </Features>
        <Link
          to="/form"
          state={{
            initialMessage:
              "🏊🏻 Deine Swimming PREMIUM 10 Stunden Unterricht 700€",
          }}
        >
          <CtaButton>Anfrage</CtaButton>
        </Link>
      </PremiumPlan>
    </PricingPlans>
  );
};

export default Offers;
