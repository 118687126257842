import React, { useState } from "react";
import {
  CoursesListContainer,
  CoursesBoxs,
  Box,
  ImgContainer,
  Img,
  HeadingTextContainer,
  HeaderText,
  SubText,
  SubTextLanguage,
  HighLight,
  BtnContainerWrapper,
  BtnContainer,
  BtnContainerInfo,
  BtnText,
  HeaderTextModal,
  HeaderBox,
  Dialog,
  CloseButton,
  Expirense,
} from "./styles";
// data discounts //
import { DiscountList } from "../../../assets/Data/Discount";

// Modal type //
interface ModalContentProps {
  onClose: () => void;
  popUp: string[];
}

// Modal content component //
const ModalContent: React.FC<ModalContentProps> = ({ onClose, popUp }) => (
  <Dialog open>
    <HeaderBox>
      <HeaderTextModal>Rabattinformationen</HeaderTextModal>
      <ul>
        {popUp.map((item, index) => (
          <li key={index}>
            <SubText>{item}</SubText>
          </li>
        ))}
      </ul>
    </HeaderBox>
    <CloseButton onClick={onClose} aria-label="close">
      ❌
    </CloseButton>
  </Dialog>
);

const Discounts: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string[]>([]);

  const openModal = (content: string[]) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  return (
    <>
      {/* Main container */}
      <CoursesListContainer isModalOpen={isModalOpen}>
        <CoursesBoxs>
          {DiscountList.map((discount) => (
            <Box key={discount.id}>
              <ImgContainer>
                <Img src={discount.img} />
              </ImgContainer>
              <HeadingTextContainer>
                <HeaderText>{discount.name}</HeaderText>
                <SubText>{discount.description}</SubText>
                <SubTextLanguage>
                Reduzierter Preis: <HighLight>{discount.cost}</HighLight>
                </SubTextLanguage>
                <Expirense>
                  Gültigkeit: <HighLight>{discount.end}</HighLight>
                </Expirense>
              </HeadingTextContainer>
              <BtnContainerWrapper>
                <BtnContainerInfo>
                  <BtnText onClick={() => openModal(discount.popUp)}>
                    Mehr Infos
                  </BtnText>
                </BtnContainerInfo>
                <BtnContainer href={discount.payment}>
                  <BtnText>Kaufen</BtnText>
                </BtnContainer>
              </BtnContainerWrapper>
            </Box>
          ))}
        </CoursesBoxs>
      </CoursesListContainer>
      {/* Modal content rendered separately */}
      {isModalOpen && (
        <ModalContent onClose={closeModal} popUp={modalContent} />
      )}
    </>
  );
};

export default Discounts;
