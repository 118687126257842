import styled from "styled-components";

export const ContainerBox = styled.div`
  width: 95%;
  max-width: 1220px;
  margin: 0 auto;
`;

export const CenterText = styled.h1`
  text-align: center;
  color: #022c43;
`;

export const TextDicriptionContainer = styled.article`
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: relative;
  margin-bottom: 20px;
`;

export const DiscriptionNum = styled.div`
  font-size: 10vw;
  font-weight: 600;
  padding: 10px 0;
  position: sticky;
  top: 0;
  text-align: center;
  height: calc(10vw + 20px);
  transition: all 0.2s ease-in;
  color: #0a0a3f;
`;

export const ContentWrapper = styled.div`
  border-top: 2px solid #fff;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 10px;
  padding: 15px 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const TitleText = styled.div`
  font-weight: 600;
  color: #001b41;
`;

export const DicriptionTextWrapper = styled.div`
  line-height: 26px;
  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

export const DiscriptionText = styled.p`
  color: #001629;
`;
