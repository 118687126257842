import styled from "styled-components";

export const FeaturesContainer = styled.div`
  display: flex;
  padding: 0 30px;
  height: 700px;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  @media screen and (max-width: 960px) {
    margin-top: 5%;
    margin-bottom: 80%;
  }
`;

export const HeaderText = styled.span`
  color: #022c43;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 2%;
  text-align: center;
  @media screen and (max-width: 960px) {
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 10%;
    line-height: 38px;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 100vh;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
  border-radius: 15px;

  &:hover,
  &:focus {
    flex: 1 1 20%;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 6%;
  }
`;

export const Images = styled.img`
  width: 100%;
  height: calc(100% - 10vh);
  object-fit: cover;
  transition: 0.5s;
  border-radius: 15px;

  ${Box}:hover & {
    width: 100%;
    height: 100%;
  }
`;

export const Spans = styled.span`
  color: #022c43;
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 8vh;
  line-height: 2.6;
  border-radius: 15px;
  @media screen and (max-width: 960px) {
    font-size: 3.5vh;
    height: 8vh;
  }
`;
