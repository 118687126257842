import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>
          Sicher und ohne Angst schwimmen lernen und schnelle Fortschritte
          erzielen
        </HeaderText>
        <SubText>
          Bist du bereit, deine Schwimmziele zu erreichen? Kontaktiere mich noch
          heute und sichere dir dein persönliches Schwimmcoaching,um deinen
          Traum wahr zu machen. Lass uns gemeinsam an deiner Schwimmreise
          arbeiten – ich freue mich auf dich!
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
