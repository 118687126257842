import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  @media screen and (max-width: 960px) {
    display: flex;
    padding: 25px 5px;
    margin-bottom: none;
  }
`;

export const MainSubContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

export const Card = styled.div`
  padding: 18px;
  border-radius: 2em;
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 960px) {
    width: auto;
  }
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 1px solid #000;
  object-fit: cover;
`;

export const Name = styled.h2`
  font-size: 24px;
  margin: 10px 0;
  color: #000;
`;

export const Bio = styled.p`
  font-size: 16px;
  color: #000;
  line-height: 1.5;
`;

export const FollowButton = styled.a`
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: #8f7b45;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0000ee;
  }
`;

export const BottomGrid = styled.div`
  margin-top: 10px;
`;
