import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

export const VideoBlockContainer = styled.div`
  padding: 40px 60px;
  @media screen and (max-width: 960px) {
    padding: 0px 10px;
    margin-bottom: 15%;
  }
`;

export const VideoBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const VideoBlockTextContainer = styled.span`
  color: #001524;
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 20px;
  opacity: 0.8;
  @media screen and (max-width: 960px) {
    font-size: 18px;
    font-weight: 900;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
    text-align: center;
  }
`;

export const VideoSearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 15px;
  color: #758694;
  font-size: 16px;
  pointer-events: none;
`;

export const VideoSearchInput = styled.input`
  width: 400px;
  height: 28px;
  padding: 8px 8px 8px 35px;
  border-radius: 100px;
  outline: none;
  font-size: 18px;
  border: none;
  background: #eee;
  color: #000;
  &::placeholder {
    color: #666;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThumbnailImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 12px;
  object-fit: cover;
  position: relative;
`;

export const ThumbnailText = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
`;

export const ThumbnailTextSub = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: #001524;
  opacity: 0.7;
  font-weight: 500;
  padding: 0px 10px;
`;