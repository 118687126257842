import React from "react";
import {
  MainContainer,
  MainWrapper,
  SubTextConatiner,
  SubText,
  BackgroundImg
} from "./styles";

const Hero = () => {
  return (
    <MainContainer>
      <MainWrapper>
        <SubTextConatiner>
          <SubText>Die Besten Coaches in Berlin - nur für dich!</SubText>
        </SubTextConatiner>
        <BackgroundImg src="https://images.unsplash.com/photo-1609674248079-e9242e48c06b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTAzfHx3ZWlnaHRzfGVufDB8fDB8fHww"/>
      </MainWrapper>
    </MainContainer>
  );
};

export default Hero;
