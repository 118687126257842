import React from "react";
import {
  HeroContainer,
  HeaderContainer,
  HeaderText,
  SubText,
  BtnContainer,
  BtnText,
  ImgContainer,
  Img,
} from "./styles";
import Trip from "../../../assets/imgs/trip.png";

const Hero = () => {
  return (
    <HeroContainer>
      <HeaderContainer>
        <HeaderText>
          Join me for an unforgettable fitness trip abroad!
        </HeaderText>
        <SubText>
          Invest in your health with a fitness holiday abroad! As your trainer,
          I'll help you achieve your goals in a stunning setting while you
          recharge.
        </SubText>
        <BtnContainer>
          <BtnText>See Plans</BtnText>
        </BtnContainer>
      </HeaderContainer>
      <ImgContainer>
        <Img src={Trip} />
      </ImgContainer>
    </HeroContainer>
  );
};

export default Hero;
