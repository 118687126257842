import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>Exklusiver Rabatt auf Fitness-Sessions!</HeaderText>
        <SubText>
          Wir bieten exklusive Fitnessangebote, mit denen du vollen Zugang zu
          all unseren Kursen zu einem stark reduzierten Preis erhältst! Egal ob
          Krafttraining oder Cardio – profitiere von diesen Rabatten und erlebe
          alle Sessions, die du brauchst, um dein Fitnessziel zu erreichen.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
