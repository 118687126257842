import React from "react";
import { MainContainer, HeaderWrapper, HeaderText, SubText } from "./styles";

const Sub = () => {
  return (
    <MainContainer>
      <HeaderWrapper>
        <HeaderText>
          Nourish to Flourish: The Essentials of Healthy Eating
        </HeaderText>
        <SubText>
          "Nourish to Flourish" emphasizes the idea that proper nutrition is the
          foundation for thriving in all aspects of life. Healthy eating is
          about more than just satisfying hunger; it's about providing your body
          with the nutrients it needs to function optimally. This includes a
          balanced intake of fruits, vegetables, whole grains, lean proteins,
          and healthy fats.
        </SubText>
      </HeaderWrapper>
    </MainContainer>
  );
};

export default Sub;
